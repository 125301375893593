/*
 * ===============================================================================================================
 *                                Copyright 2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import React from 'react';
import { usePortalApp } from './context/app/provider';
import { THEMES, THEMES_V2, WITH_LIGHT_THEME_UPDATE } from './utils/constants';
import { LuiThemeProvider } from '@jda/lui-common-component-library';
import { getFeatureFlag } from '@jda/lui-portal-utilities';

interface Props {
  children: React.ReactNode;
}

const ThemeProviderComponent = ({ children }: Props) => {
  const { appState } = usePortalApp();
  const isLightThemeUpdateEnabled = getFeatureFlag(WITH_LIGHT_THEME_UPDATE);

  const themesOptions = isLightThemeUpdateEnabled ? THEMES_V2 : THEMES;

  return (
    <LuiThemeProvider theme={themesOptions[appState.theme as keyof typeof themesOptions]}>{children}</LuiThemeProvider>
  );
};

const ThemeWrapper = ({ children }: Props) => {
  return <ThemeProviderComponent>{children}</ThemeProviderComponent>;
};

export default ThemeWrapper;
