/*
 * ===============================================================================================================
 *                                Copyright 2023, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import {
  NavigationInterface,
  NavigationPoint,
  PortalNavigationInstance,
  TaskBundleDetail,
  TaskBundleDisplay,
} from '../../../models/portalNavigation.model';
import PortalNavigationAction, { PortalNavigationActionType } from './action';

export interface PortalNavigationState {
  portalInstances?: PortalNavigationInstance[];
  selectedPortalInstance: PortalNavigationInstance | undefined;
  activeMenuInstance: PortalNavigationInstance | undefined;
  taskBundles: TaskBundleDisplay[];
  selectedPortalTaskBundle: TaskBundleDetail | undefined;
  effectiveInterfaces: NavigationInterface[];
  requestedPoint: NavigationPoint | undefined;
  isInitialized: boolean;
  navPointAncestors: string[] | undefined;
}

export const initialPortalNavigationState: PortalNavigationState = {
  isInitialized: false,
  portalInstances: undefined,
  selectedPortalInstance: undefined,
  activeMenuInstance: undefined,
  taskBundles: [],
  effectiveInterfaces: [],
  requestedPoint: undefined,
  selectedPortalTaskBundle: undefined,
  navPointAncestors: undefined,
};

const PortalNavigationReducer = (
  state = initialPortalNavigationState, // eslint-disable-line no-eval
  action: PortalNavigationAction
): PortalNavigationState => {
  switch (action.type) {
    case PortalNavigationActionType.SET_INSTANCES:
      return {
        ...state,
        portalInstances: action.payload,
      };
    case PortalNavigationActionType.SET_SELECTED_INSTANCE:
      return {
        ...state,
        selectedPortalInstance: action.payload,
      };
    case PortalNavigationActionType.SET_ACTIVE_MENU_INSTANCE:
      return {
        ...state,
        activeMenuInstance: action.payload,
      };
    case PortalNavigationActionType.SET_TASK_BUNDLES:
      return {
        ...state,
        taskBundles: action.payload,
      };
    case PortalNavigationActionType.SET_INITIALIZED:
      const {
        portalInstances,
        selectedPortalInstance,
        selectedPortalTaskBundle,
        effectiveInterfaces,
        requestedPoint,
        navPointAncestors,
      } = action.payload;
      return {
        ...state,
        isInitialized: true,
        portalInstances: portalInstances || [],
        activeMenuInstance: selectedPortalInstance || state.activeMenuInstance,
        selectedPortalInstance,
        selectedPortalTaskBundle,
        effectiveInterfaces,
        requestedPoint,
        navPointAncestors,
      };
    default:
      return state;
  }
};

export default PortalNavigationReducer;
