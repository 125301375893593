/*
 * ===============================================================================================================
 *                                Copyright 2023-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import {
  LogoutAbortMessage,
  LogoutRequestedMessage,
  MessageActions,
  getFeatureFlag,
  useI18nContext,
} from '@jda/lui-portal-utilities';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { AuthService } from '../../auth/authService';
import LogoutScreen from '../../components/loggingOut/logoutScreen';
import { TimeoutModal } from '../../components/TimeoutModal';
import { usePortalApp } from '../../context/app/provider';
import { IdleTimerActions, TimerEvent } from '../../models/Timer';
import { portalDebug } from '../../utils/loggingFlags';
import { getRealmSettings } from '../../utils/realmSettings';
import {
  ACKNOWLEDGED_MFE_COUNT_KEY,
  LOGOUT_CANCEL_KEY,
  LOGOUT_CLEANUP_KEY,
  LOGOUT_REJECT_KEY,
  PORTAL_LOGOUT_KEY,
  TIMEOUT_OPT_IN_KEY,
  VERIFY_PORTAL_ACTIVITY,
} from '../../utils/timerListener';
import {
  LogoutReject,
  SetInitialTimeoutValues,
  SetLogoutCleanupTimeout,
  SetTimeoutModalDisplay,
  StartIdleLogout,
  TimeoutOptIn,
} from './store/actions';
import timerReducer, { initialTimerState } from './store/reducer';
import { useGlobalUIComponents } from '../global-ui-components/provider';
import { PushMessage } from '../global-ui-components/store/actions';
import { STORAGE_KEY } from '../../auth/sessionService';
import {
  LOCAL_STORAGE_LAST_CONNECTION,
  LOCAL_STORAGE_TIMEOUT_OPT_IN,
  WITH_TIMEOUT_OPT_ON_CLOSE_TABS,
} from '../../utils/constants';
import { isValid } from 'date-fns';
import { removeTimeoutOtpFromStorage } from '../../utils/appUtils';
let idleTimer: IdleTimerActions | null = null;
let logoutTimeout: NodeJS.Timeout;
let cleanupTimeout: NodeJS.Timeout;
const SESSION_HEARTBEAT_INTERVAL_FALLBACK = 300;
const MAX_DELAY_VALUE = 2147483646;
const { PORTAL_IDLE_TIMEOUT, SESSION_HEARTBEAT_INTERVAL, EVENTS_THROTTLE_TIME } = window['env'];

interface Props {
  children: React.ReactNode;
}

interface TimersContextValues {
  handleMfeActivity: () => void;
  logoutEventSubscription: (timeout: number) => void;
  handleRejectLogoutRequest: () => void;
  handleTimeoutOptIn: (cleanupTime?: number) => void;
  handlePortalMessageEvent: (key: string, newValue?: string) => void;
}

function TimersProvider(props: Props) {
  const [timerState, timerDispatch] = useReducer(timerReducer, initialTimerState);
  const { appState, appDispatch, sendMessageToCurrentApp } = usePortalApp();
  const { dispatchGlobalUIComponentsState } = useGlobalUIComponents();
  const { localizer } = useI18nContext().store;
  const [sessionId, setSessionId] = useState<string | null>(null);

  const isTimeoutOptOnCloseTabs = getFeatureFlag(WITH_TIMEOUT_OPT_ON_CLOSE_TABS);

  const handleUserIdle = () => {
    portalDebug('The portal user has gone idle.');
    idleTimer?.pause();
    timerDispatch(new SetTimeoutModalDisplay(true));
    logoutTimeout = setTimeout(() => {
      startIdleLogout();
    }, timerState.timeoutWarningTime);
  };

  const registerTimerEvents = ({ key, newValue }: TimerEvent) => {
    if (key === PORTAL_LOGOUT_KEY) {
      AuthService.getInstance().logout();
    }
    if (key === LOGOUT_CANCEL_KEY) {
      portalDebug('an mfe has canceled logout');
      clearTimeout(logoutTimeout);
      timerDispatch(new SetTimeoutModalDisplay(false));
    }
    if (key === LOGOUT_REJECT_KEY) {
      clearTimeout(cleanupTimeout);
      timerDispatch(new LogoutReject());
      const logoutAbortMessage = new LogoutAbortMessage();
      sendMessageToCurrentApp(logoutAbortMessage);
    }
    if (key === LOGOUT_CLEANUP_KEY) {
      portalDebug('an mfe has set logout cleanup timeout');
      timerDispatch(new SetLogoutCleanupTimeout(parseInt(newValue ?? '0', 10)));
    }
    if (key === TIMEOUT_OPT_IN_KEY) {
      portalDebug('an mfe has opted in for timeout');
      timerDispatch(new TimeoutOptIn());
    }
    if (key === VERIFY_PORTAL_ACTIVITY) {
      localStorage.removeItem(LOCAL_STORAGE_LAST_CONNECTION);
    }
  };

  const { start, pause, message } = useIdleTimer({
    timeout: timerState.inactivityTimeout - timerState.timeoutWarningTime || 360000, // initial value is this number because react-idle-timer crashes if this is 0
    eventsThrottle: EVENTS_THROTTLE_TIME || 30000,
    crossTab: true,
    name: 'idle-timer',
    startManually: true,
    onIdle: handleUserIdle,
    onMessage: registerTimerEvents,
  });
  const handlePortalMessageEvent = React.useCallback(
    (key: string, newValue?: string, emitOnSelf = true) => {
      message({ key, newValue }, emitOnSelf);
    },
    [message]
  );

  useEffect(() => {
    // Implementing the setInterval method
    async function heartbeat() {
      await AuthService.getInstance().sendHeartBeat();
    }
    let interval: string | number | NodeJS.Timeout | undefined;
    if (sessionId) {
      heartbeat();
      interval = setInterval(() => {
        heartbeat();
      }, (SESSION_HEARTBEAT_INTERVAL || SESSION_HEARTBEAT_INTERVAL_FALLBACK) * 1000);
    }

    return () => clearInterval(interval);
  }, [sessionId]);

  useEffect(() => {
    function storageEventHandler(event: any) {
      if (event.key === STORAGE_KEY) {
        const id = event.newValue;
        setSessionId(id);
      }
    }
    window.addEventListener('storage', storageEventHandler);
  }, []);

  const getDiffInMilliseconds = (date1: Date, date2: Date) =>
    // @ts-ignore
    date2 - date1;

  useEffect(() => {
    const lastConnection = localStorage.getItem(LOCAL_STORAGE_LAST_CONNECTION);
    if (timerState.inactivityTimeout) {
      if (
        localStorage.getItem(LOCAL_STORAGE_TIMEOUT_OPT_IN) === 'true' &&
        isTimeoutOptOnCloseTabs &&
        lastConnection &&
        isValid(new Date(lastConnection))
      ) {
        const currentDateTime = new Date().toISOString();

        const diffInMilliseconds = getDiffInMilliseconds(new Date(lastConnection), new Date(currentDateTime));
        if (diffInMilliseconds >= timerState.inactivityTimeout) {
          removeTimeoutOtpFromStorage();
          handlePortalMessageEvent(PORTAL_LOGOUT_KEY); // calling all portal instances 🚨 logout
          AuthService.getInstance().logout();
        } else {
          localStorage.removeItem(LOCAL_STORAGE_LAST_CONNECTION);
        }
      }
    }
  }, [timerState.inactivityTimeout]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (localStorage.getItem(LOCAL_STORAGE_TIMEOUT_OPT_IN) === 'true') {
        localStorage.setItem(LOCAL_STORAGE_LAST_CONNECTION, new Date().toISOString());
        handlePortalMessageEvent(VERIFY_PORTAL_ACTIVITY, undefined, false); // calling all portal instances 🚨 logout
        portalDebug('The portal user has close the tab');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const getTimeoutValues = async () => {
    const realmSettingsResponse = await getRealmSettings();

    let inactivityTimeout = realmSettingsResponse.inactivityTimeout * 1000;
    if (inactivityTimeout > MAX_DELAY_VALUE) inactivityTimeout = MAX_DELAY_VALUE;
    if (inactivityTimeout < 0) inactivityTimeout = 0;
    let timeoutWarningTime = realmSettingsResponse.timeoutWarning * 1000;
    // timeoutWarning must be lower than inactivityTimeout
    if (timeoutWarningTime >= inactivityTimeout) timeoutWarningTime = inactivityTimeout - 1;
    if (timeoutWarningTime < 0) timeoutWarningTime = 0;

    portalDebug(
      'an mfe has set timeout values inactivityTimeout: ' +
        inactivityTimeout +
        ' timeoutWarningTime: ' +
        timeoutWarningTime
    );

    timerDispatch(
      new SetInitialTimeoutValues({
        inactivityTimeout: inactivityTimeout,
        timeoutWarningTime: timeoutWarningTime,
      })
    );
  };

  useEffect(() => {
    localStorage.removeItem(ACKNOWLEDGED_MFE_COUNT_KEY);
    getTimeoutValues();
  }, []);

  const logoutEventSubscription = React.useCallback(
    (timeout: number) => {
      const storedCleanupTimeout: number = parseInt(window.localStorage.getItem(LOGOUT_CLEANUP_KEY) || '0', 10);
      if (timeout > storedCleanupTimeout) {
        handlePortalMessageEvent(LOGOUT_CLEANUP_KEY, timeout.toString());
        window.localStorage.setItem(LOGOUT_CLEANUP_KEY, timeout.toString());
        timerDispatch(new SetLogoutCleanupTimeout(timeout));
      }
    },
    [timerDispatch]
  );

  const handleMfeActivity = React.useCallback(() => {
    portalDebug('Idle timer reset by user activity.');
    idleTimer?.start();
  }, [idleTimer]);

  const startIdleLogout = () => {
    timerDispatch(new StartIdleLogout());
    portalDebug('Starting logout request.');
    sendLogoutRequest();
    portalDebug('Waiting for mfes cleanup...');
    cleanupTimeout = setTimeout(() => {
      portalDebug("Mfe's cleanup time reached.");
      AuthService.getInstance().logout();
    }, timerState.logoutCleanupTimeout);
  };

  const handleLogoutTimeoutModalClose = () => {
    handlePortalMessageEvent(LOGOUT_CANCEL_KEY); // calling all portal instances 🚨 cancel logout
    clearTimeout(logoutTimeout);
    idleTimer?.start();

    timerDispatch(new SetTimeoutModalDisplay(false));
  };

  const handleRejectLogoutRequest = React.useCallback(() => {
    handlePortalMessageEvent(LOGOUT_REJECT_KEY); // calling all portal instances 🚨 abort logout process
    clearTimeout(cleanupTimeout);
    idleTimer?.start();
    timerDispatch(new LogoutReject());
    dispatchGlobalUIComponentsState(
      new PushMessage({
        message: localizer({
          key: 'snackbar.message',
          valueSubstitutions: { application: appState.currentApplication?.displayName ?? '' },
        }),
        severity: 'success',
      })
    );
  }, [
    idleTimer,
    timerDispatch,
    appDispatch,
    handlePortalMessageEvent,
    localizer,
    appState.currentApplication?.displayName,
  ]);

  const handleTimeoutOptIn = React.useCallback(
    (cleanupTime?: number) => {
      if (cleanupTime) {
        logoutEventSubscription(cleanupTime);
      }
      handlePortalMessageEvent(TIMEOUT_OPT_IN_KEY, cleanupTime?.toString()); // calling all portal instances 🚨 timer is set and active
      if (isTimeoutOptOnCloseTabs) {
        localStorage.setItem(LOCAL_STORAGE_TIMEOUT_OPT_IN, 'true');
      }
      timerDispatch(new TimeoutOptIn());
    },
    [timerDispatch, logoutEventSubscription, handlePortalMessageEvent]
  );

  const logout = () => {
    handlePortalMessageEvent(PORTAL_LOGOUT_KEY); // calling all portal instances 🚨 logout
    timerDispatch(new SetTimeoutModalDisplay(false));
    AuthService.getInstance().logout();
  };

  const sendLogoutRequest = () => {
    let logoutRequestedMessage: LogoutRequestedMessage;
    logoutRequestedMessage = {
      action: MessageActions.LogoutRequested,
      status: 200,
      messageId: 'logoutRequested',
    };
    sendMessageToCurrentApp(logoutRequestedMessage);
  };

  const contextValue = React.useMemo(() => {
    return {
      handleMfeActivity,
      logoutEventSubscription,
      handleRejectLogoutRequest,
      handleTimeoutOptIn,
      handlePortalMessageEvent,
    };
  }, [
    handleMfeActivity,
    logoutEventSubscription,
    handleRejectLogoutRequest,
    handleTimeoutOptIn,
    handlePortalMessageEvent,
  ]);

  useEffect(() => {
    idleTimer =
      PORTAL_IDLE_TIMEOUT && timerState.inactivityTimeout > 0 && timerState.timeoutOptIn ? { start, pause } : null;
  }, [PORTAL_IDLE_TIMEOUT, timerState.inactivityTimeout, timerState.timeoutOptIn]);

  return (
    <TimersContext.Provider value={contextValue}>
      {PORTAL_IDLE_TIMEOUT && timerState.inactivityTimeout > 0 && (
        <>
          <TimeoutModal
            isOpen={timerState.isLogoutTimeoutModalDisplayed}
            timeout={timerState.timeoutWarningTime}
            handleClose={handleLogoutTimeoutModalClose}
            handleLogout={logout}
          />
          {timerState.isDoingCleanup ? <LogoutScreen /> : null}
        </>
      )}
      {props.children}
    </TimersContext.Provider>
  );
}

export const TimersContext = createContext<TimersContextValues>({
  handleMfeActivity: () => {},
  logoutEventSubscription: () => {},
  handleRejectLogoutRequest: () => {},
  handleTimeoutOptIn: () => {},
  handlePortalMessageEvent: () => {},
});

export const usePortalTimer = () => useContext(TimersContext);

export default TimersProvider;
