/*
 * ===============================================================================================================
 *                                Copyright 2023-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import {
  BreadcrumbsNavigationResponseMessage,
  getEffectivePreferences,
  getFeatureFlag,
  HamburgerTriggerMessage,
  Message,
  MessageActions,
  Preferences,
  ThemeSwitchResponseMessage,
  UserPreferences,
  UserPreferencesResponseMessage,
} from '@jda/lui-portal-utilities';
import { NavigationInterface, NavigationPoint } from '../../models/portalNavigation.model';
import { Application } from '../../models/application.model';
import { usePortalApplicationBar } from '../application-bar/provider';
import {
  IFRAME_ZINDEX,
  IFRAME_ZINDEX_OFFSET,
  PORTAL_FRAME_TITLE,
  WITH_TITLE_UPDATE,
  PORTAL_HELP_ADDRESS_DEFAULT,
  WITH_PORTAL_HELP,
  PortalHelpLaunchOptions,
  WITH_COOKIES_OPT_IN,
  PREFERENCES_APP,
  COOKIES_PREFERENCES_OPTIONS,
} from '../../utils/constants';
import { appInsights, setAiAppsData } from '../../insights/AppInsights';
import { generateTitle } from '../../components/breadcrumbs/HeaderBreadcrumbs';
import { usePortalDrawer } from '../application-drawer/provider';
import { useI18nContext } from '../i18n';
import {
  setThemeSelectionStorage,
  setThemeStorage,
  updatePortalTitle,
  validateOptInCookies,
  WakeLockHandler,
} from '../../utils/appUtils';
import { makeStyles } from '@material-ui/core';
import reducer, { initialAppState, AppState } from './store/reducer';
import AppAction, {
  SetTheme,
  SetThemeSelection,
  SetUserPreferences,
  SetWakelock,
  UpdateCurrentApplication,
} from './store/actions';
import { WITH_LIGHT_THEME_UPDATE } from '../../utils/constants';

const { HEADER_HEIGHT, MENU_WIDTH } = window['env'];

const useStyles = makeStyles(() => {
  return {
    iFrame: {
      height: `calc(100vh - ${HEADER_HEIGHT})`,
      top: HEADER_HEIGHT,
      position: 'fixed',
      border: 'none',
    },
    iFramezIndexForward: { zIndex: IFRAME_ZINDEX + IFRAME_ZINDEX_OFFSET },
    iFramezIndexBackward: { zIndex: IFRAME_ZINDEX },
    iFrameFullWidth: {
      left: 0,
      width: '100%',
    },
    iFrameSize: {
      left: MENU_WIDTH,
      width: `calc(100% - ${MENU_WIDTH})`,
    },
  };
});

interface Props {
  children: React.ReactNode;
  iframeRef: HTMLIFrameElement;
}

interface BreadcrumbsNavigationResponseMessageEvent extends Omit<BreadcrumbsNavigationResponseMessage, 'breadcrumbs'> {}

export interface AppContextValues {
  appState: AppState;
  appDispatch: React.Dispatch<AppAction>;
  iframeRef: HTMLIFrameElement;
  sendMessageToCurrentApp: (message: Message) => void;
  sendUserPreferences: (preferences: Preferences, messageId?: string) => void;
  handleSetUserPreferences: (preferencesResp: Preferences) => void;
  handleSetPortalOnlyUserPreferences: (preferencesResp: Preferences) => void;
  updateCurrentApplication: (
    applicationIndex: number,
    applicationSubNav: string | undefined,
    fromMenuClick: boolean,
    navPoint: NavigationPoint | Application | NavigationInterface,
    clearBreadcrumbs?: boolean
  ) => void;
  handlePortalNavigation: (application: Application | NavigationPoint, navigationUrl: string) => void;
  toggleTheme: (isDarkTheme: boolean) => void;
  hamburgerMenuOpen: () => void;
  openHelp: () => void;
}

function AppProvider(props: Props) {
  const classes = useStyles(props);
  const [appState, appDispatch] = useReducer(reducer, initialAppState);

  const drawer = usePortalDrawer();
  const { handleSetIsLoadingApplication } = usePortalApplicationBar();
  const { localizer } = useI18nContext().store;

  const [messageEvent, setMessageEvent] = useState<BreadcrumbsNavigationResponseMessageEvent | undefined>(undefined);

  const isTitleUpdateEnabled = getFeatureFlag(WITH_TITLE_UPDATE);
  const helpOptionSelection = getFeatureFlag(WITH_PORTAL_HELP);
  const isLightThemeUpdateEnabled = getFeatureFlag(WITH_LIGHT_THEME_UPDATE);

  const isOptInCookiesEnabled = getFeatureFlag(WITH_COOKIES_OPT_IN);
  const shouldTrackCookies: boolean = validateOptInCookies(appState.userPreferences || {}, isOptInCookiesEnabled);

  useEffect(() => {
    props.iframeRef.classList.replace('mfe', classes.iFrame);
    props.iframeRef.classList.add(classes.iFrameSize);
    props.iframeRef.classList.add(classes.iFramezIndexBackward);
    props.iframeRef.onload = () => handleSetIsLoadingApplication(false);
  }, []);

  useEffect(() => {
    const navPointsLength = appState.navPoints?.length || 0;
    if (props.iframeRef) {
      if (
        appState.currentApplication &&
        !(appState.currentApplication as Application).showNavigation &&
        !appState.useHierarchicalNavigation
      ) {
        props.iframeRef.classList.replace(classes.iFrameSize, classes.iFrameFullWidth);
        drawer.hideDrawer();
      } else if (
        appState.useHierarchicalNavigation &&
        (navPointsLength === 0 ||
          (navPointsLength === 1 &&
            appState.navPoints[0].resourceId === (appState.currentApplication as NavigationPoint).resourceId))
      ) {
        props.iframeRef.classList.replace(classes.iFrameFullWidth, classes.iFrameSize);
        drawer.hideDrawer();
      } else {
        props.iframeRef.classList.replace(classes.iFrameFullWidth, classes.iFrameSize);
        drawer.visibilizeDrawer();
      }
      if (appState.currentApplication && appState.showBackdrop) {
        props.iframeRef.classList.replace(classes.iFramezIndexBackward, classes.iFramezIndexForward);
      } else {
        props.iframeRef.classList.replace(classes.iFramezIndexForward, classes.iFramezIndexBackward);
      }
    }
  }, [appState.currentApplication, appState.showBackdrop]);

  const updateCurrentApplication = React.useCallback(
    (
      applicationIndex: number,
      applicationSubNav: string | undefined,
      fromMenuClick: boolean,
      navPoint: NavigationPoint | Application,
      clearBreadcrumbs: boolean = true
    ): void => {
      if (navPoint.navigationHandler) {
        navPoint.navigationHandler();
      } else {
        if (shouldTrackCookies) {
          setAiAppsData({ application: navPoint, subnav: applicationSubNav });
          appInsights.trackPageView({ name: navPoint.namespace, uri: applicationSubNav });
        }
        drawer.updateActiveNavPoint(applicationIndex);
        appDispatch(
          new UpdateCurrentApplication({
            fromMenuClick,
            breadcrumbs: clearBreadcrumbs ? [] : undefined,
            currentApplication: { ...navPoint, subnav: applicationSubNav },
            portalTitle: clearBreadcrumbs ? null : undefined,
            helpAddress: clearBreadcrumbs ? PORTAL_HELP_ADDRESS_DEFAULT : undefined,
          })
        );
      }
    },
    [drawer.updateActiveNavPoint, appDispatch]
  );

  const sendMessageToCurrentApp = React.useCallback(
    (message: Message) => {
      if (props.iframeRef.contentWindow && appState.currentApplication) {
        props.iframeRef.contentWindow.postMessage(message, appState.currentApplication.frameUrl || '');
      }
    },
    [appState.currentApplication]
  );

  useEffect(() => {
    if (messageEvent) {
      const navigationBreadcrumbs = appState.breadcrumbs;
      navigationBreadcrumbs.pop();

      const breadcrumbsResponse: BreadcrumbsNavigationResponseMessage = {
        ...messageEvent,
        breadcrumbs: navigationBreadcrumbs,
      };
      sendMessageToCurrentApp(breadcrumbsResponse);
      setMessageEvent(undefined);
    }
  }, [messageEvent]);

  const handlePortalNavigation = React.useCallback(
    (application: Application | NavigationPoint, navigationUrl: string): void => {
      const index = appState.useHierarchicalNavigation
        ? appState.navPoints.findIndex(({ namespace }) => namespace === application.namespace)
        : appState.applicationList.findIndex(({ namespace }) => namespace === application.namespace);
      const appChange = appState.currentApplication?.namespace !== application.namespace;
      updateCurrentApplication(index, navigationUrl, true, application, appChange);
      if (!appChange) {
        setMessageEvent({
          status: 200,
          path: navigationUrl,
          messageId: `Breadcrumbs ${appState.currentApplication!.frameUrl}${navigationUrl}`,
          action: MessageActions.BreadcrumbsNavigationResponse,
        });
      } else if (appState.wakelockEnabled) {
        const wakeLockHandler = new WakeLockHandler();
        wakeLockHandler.releaseWakeLock();
        appDispatch(new SetWakelock(false));
      }
    },
    [
      appDispatch,
      appState.currentApplication,
      appState.useHierarchicalNavigation,
      appState.navPoints,
      appState.applicationList,
      appState.wakelockEnabled,
      sendMessageToCurrentApp,
      updateCurrentApplication,
    ]
  );
  useEffect(() => {
    if (appState.currentApplication) {
      const mfeTitle = generateTitle(appState.currentApplication, localizer);
      props.iframeRef.title = mfeTitle;
      if (isTitleUpdateEnabled) {
        const appTitle = appState.portalTitle || mfeTitle;
        updatePortalTitle({ frameTitle: PORTAL_FRAME_TITLE, appTitle });
      }
    }
  }, [
    appState.isInitialized,
    appState.currentApplication?.namespace,
    appState.portalTitle,
    localizer,
    isTitleUpdateEnabled,
  ]);

  const sendUserPreferences = React.useCallback(
    (preferences: Preferences, messageId?: string) => {
      let userPreferencesResponse: UserPreferencesResponseMessage;
      userPreferencesResponse = {
        status: 200,
        userPreferences: new UserPreferences(preferences),
        messageId: messageId || `new userPreferencesMessage`,
        action: MessageActions.UserPreferencesResponse,
      };
      sendMessageToCurrentApp(userPreferencesResponse);
    },
    [sendMessageToCurrentApp]
  );

  const handleSetUserPreferences = React.useCallback(
    (preferencesResp: Preferences) => {
      appDispatch(new SetUserPreferences(preferencesResp));
      sendUserPreferences(preferencesResp);
    },
    [appDispatch, sendUserPreferences]
  );

  const handleSetPortalOnlyUserPreferences = React.useCallback(
    (preferencesResp: Preferences) => {
      appDispatch(new SetUserPreferences(preferencesResp));
    },
    [appDispatch]
  );

  const toggleTheme = React.useCallback(
    async (isDarkTheme: boolean) => {
      const shade = isDarkTheme ? 'dark' : 'light';
      let themeSelection: string;
      if (isLightThemeUpdateEnabled) {
        themeSelection = isDarkTheme ? 'dark' : 'ultraLight';
      } else {
        themeSelection = shade;
      }
      const themeResponse: ThemeSwitchResponseMessage = {
        status: 200,
        theme: shade,
        themeSelection: themeSelection,
        messageId: shade,
        action: MessageActions.ThemeSwitchResponse,
      };
      if (appState.currentApplication) {
        sendMessageToCurrentApp(themeResponse);
      }
      appDispatch(new SetTheme(shade));
      await setThemeStorage(shade);
      appDispatch(new SetThemeSelection(themeSelection));
      await setThemeSelectionStorage(themeSelection);
    },
    [appDispatch, appState.currentApplication, sendMessageToCurrentApp]
  );

  const hamburgerMenuOpen = React.useCallback(() => {
    const triggerHamburgerMenu: HamburgerTriggerMessage = {
      messageId: `Trigger Hamburger Menu`,
      action: MessageActions.HamburgerTrigger,
    };
    if (appState.currentApplication) {
      sendMessageToCurrentApp(triggerHamburgerMenu);
    }
  }, [appState.currentApplication, sendMessageToCurrentApp]);

  const openHelp = React.useCallback(() => {
    switch (helpOptionSelection) {
      case PortalHelpLaunchOptions.NEW_TAB:
        window.open(appState.helpAddress, '_blank');
        break;
      case PortalHelpLaunchOptions.SHARED_TAB:
        window.open(appState.helpAddress, `portalHelp${window.location.hostname}`);
        break;
      default:
        console.warn('Help is not enabled.');
        break;
    }
  }, [appState.helpAddress, helpOptionSelection]);

  const contextValue = React.useMemo(() => {
    return {
      appState,
      appDispatch,
      iframeRef: props.iframeRef,
      sendMessageToCurrentApp,
      sendUserPreferences,
      handleSetUserPreferences,
      handleSetPortalOnlyUserPreferences,
      updateCurrentApplication,
      handlePortalNavigation,
      toggleTheme,
      openHelp,
      hamburgerMenuOpen,
    };
  }, [
    appState,
    appDispatch,
    sendMessageToCurrentApp,
    sendUserPreferences,
    handleSetUserPreferences,
    handleSetPortalOnlyUserPreferences,
    updateCurrentApplication,
    handlePortalNavigation,
    toggleTheme,
    hamburgerMenuOpen,
    openHelp,
  ]);

  // @ts-ignore
  return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>;
}

export const AppContext = createContext<AppContextValues>({
  appState: initialAppState,
  appDispatch: () => {},
  iframeRef: {} as HTMLIFrameElement,
  sendMessageToCurrentApp: () => {},
  sendUserPreferences: () => {},
  handleSetUserPreferences: () => {},
  handleSetPortalOnlyUserPreferences: () => {},
  updateCurrentApplication: () => {},
  handlePortalNavigation: () => {},
  toggleTheme: () => {},
  hamburgerMenuOpen: () => {},
  openHelp: () => {},
});

export const usePortalApp = () => useContext(AppContext);

export default AppProvider;
