/*
 * ===============================================================================================================
 *                                Copyright 2020-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import axios from 'axios';
import { AuthService } from '../auth/authService';
import { Application } from '../models/application.model';
import { DisplayIndexedData, NavAssociations, NavigationPoint } from '../models/portalNavigation.model';
import { LOCAL_PORTAL_CONFIGURATION_PLACEHOLDER, registrationUrl } from './constants';
import { getUrlNamespace } from './urlJoin';
import { ILocalizer } from '@jda/lui-portal-utilities';
import { PortalNavigationState } from '../context/portal-navigation/store/reducer';

export const DASHBOARD_APP = 'home';
export const LANDING_PAGE_APP = '?landing_page?';
export const LOCAL_DEV = 'local-dev';
export const GALLERY_APP = 'app-gallery';
export const GALLERY_APP_TYPE = 'appGallery';
export const ANALYTICS_APP = 'analytics';
export const SYSTEM_APP = 'system';
export const PORTAL_APP = 'portal';
const MORE_APPS_BUTTON = {
  _id: '00000000002',
  resourceId: '00000000002',
  tenant: 'GLOBAL',
  type: 'link',
  owner: '',
  enabled: true,
  displayName: 'drawer.item.more_apps',
  description: 'drawer.item.more_apps',
  icon: 'more-vertical',
  iconColor: '#FFFFFF',
  languagePackNamespace: 'plat.lui.portal',
  isRoot: true,
  children: [],
  portalService: false,
  namespace: '',
  isMoreAppsItem: true,
  disableDrawerToggleOnClick: true,
};

interface AppConstraints {
  namespace?: string;
}

export const getApplicationList = async (navigationHandler: () => void) => {
  const {
    LOCAL_PORTAL_CONFIGURATION,
    LOCAL_MFE,
    LOCAL_ERROR_LOGOUT,
    LANDING_PAGE_LABEL,
    LANDING_PAGE_LANGUAGE_PACK,
    LANDING_PAGE_ICON,
    LANDING_PAGE_ICON_COLOR,
  } = window.env;
  const appListUrl: string = `${registrationUrl}/apps`;
  const token = await AuthService.getInstance().getAuthToken();
  const request = axios.create({
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  });
  let foundApplications: any = {
    data: {
      apps: [],
    },
  };

  try {
    foundApplications = await request.get(appListUrl);
  } catch (error) {
    if (!LOCAL_PORTAL_CONFIGURATION || LOCAL_ERROR_LOGOUT) {
      await AuthService.getInstance().logoutWithError('server_error');
    }
  }

  if (LOCAL_MFE && LOCAL_MFE.trim() !== '') {
    getLocalApps().forEach((app: any) => {
      foundApplications.data.apps.push(app);
    });
  }

  if (LOCAL_PORTAL_CONFIGURATION && LOCAL_PORTAL_CONFIGURATION !== LOCAL_PORTAL_CONFIGURATION_PLACEHOLDER) {
    foundApplications = {
      data: {
        apps: [],
      },
    };
    getLocalApps().forEach((app: any) => {
      foundApplications.data.apps.push(app);
    });
  }

  if (!LOCAL_MFE) {
    foundApplications.data.apps.push({
      _id: '00000000001',
      tenant: 'GLOBAL',
      namespace: LANDING_PAGE_APP,
      displayName: 'landingpage.label' || LANDING_PAGE_LABEL,
      enabled: true,
      types: ['system'],
      showNavigation: true,
      headerName: 'landingpage.label' || LANDING_PAGE_LABEL,
      icon: LANDING_PAGE_ICON || 'nav-home',
      iconColor: LANDING_PAGE_ICON_COLOR || '#A2D72B',
      isLocalApp: false,
      languagePackNamespace: 'plat.lui.portal' || LANDING_PAGE_LANGUAGE_PACK,
      navigationHandler,
    });
  }

  return foundApplications;
};

export const getLocalApps = () => {
  const { LOCAL_PORTAL_CONFIGURATION, LOCAL_MFE } = window.env;
  if (LOCAL_PORTAL_CONFIGURATION && LOCAL_PORTAL_CONFIGURATION !== LOCAL_PORTAL_CONFIGURATION_PLACEHOLDER) {
    return JSON.parse(decodeURI(window.env?.LOCAL_PORTAL_CONFIGURATION))?.localApps;
  } else {
    return [
      {
        _id: '00000000000',
        tenant: 'JDA',
        namespace: LOCAL_DEV,
        displayName: ' **Your Local MFE**',
        description: 'Local Micro Front-tend Application',
        enabled: true,
        types: ['system'],
        showNavigation: true,
        frameUrl: `${LOCAL_MFE}`,
        headerName: 'Local Micro Frontend Sample',
        icon: 'nav-factory',
        iconColor: '#BE7CFF',
        isLocalApp: true,
      },
    ];
  }
};

const moveAppToTop = <T extends AppConstraints>(apps: T[], namespace: string): T[] => {
  const result = [...apps];
  const app = result.find((a) => a.namespace === namespace);
  if (app) {
    result.splice(result.indexOf(app), 1);
    result.unshift(app);
  }
  return result;
};

const moveAppToBottom = <T extends AppConstraints>(apps: T[], namespace: string): T[] => {
  const result = [...apps];
  const app = result.find((a) => a.namespace === namespace);
  if (app) {
    result.splice(result.indexOf(app), 1);
    result.push(app);
  }
  return result;
};

export const getSystemApps = (apps: Application[]): Application[] => {
  let { LOCAL_MFE } = window.env;

  let allSystemApps = apps.filter(({ types }) => {
    return types?.includes(PORTAL_APP) || types?.includes(SYSTEM_APP);
  });

  // sort them by name
  allSystemApps.sort((a, b) => {
    return a.displayName.localeCompare(b.displayName);
  });

  if (LOCAL_MFE) {
    allSystemApps = moveAppToTop(allSystemApps, LOCAL_MFE);
  } else {
    allSystemApps = moveAppToTop(allSystemApps, DASHBOARD_APP);
    allSystemApps = moveAppToTop(allSystemApps, LANDING_PAGE_APP);
  }

  allSystemApps = moveAppToBottom(allSystemApps, ANALYTICS_APP);
  allSystemApps = moveAppToBottom(allSystemApps, GALLERY_APP);

  return allSystemApps;
};

export const splitPortalNavPoints = (navPoints: NavigationPoint[]): [NavigationPoint[], NavigationPoint[]] => {
  const { LOCAL_MFE } = window.env;
  let portalServices: NavigationPoint[] = [];
  let nonServiceNavPoints: NavigationPoint[] = [];

  navPoints.forEach((navPoint) => {
    if (navPoint.portalService) {
      portalServices.push(navPoint);
    } else {
      nonServiceNavPoints.push(navPoint);
    }
  });

  // sort them by name
  portalServices.sort((a, b) => {
    return a.displayName.localeCompare(b.displayName);
  });

  if (LOCAL_MFE) {
    portalServices = moveAppToTop(portalServices, LOCAL_MFE);
  } else {
    portalServices = moveAppToTop(portalServices, DASHBOARD_APP);
    portalServices = moveAppToTop(portalServices, LANDING_PAGE_APP);
  }

  portalServices = moveAppToBottom(portalServices, ANALYTICS_APP);
  portalServices = moveAppToBottom(portalServices, GALLERY_APP);

  return [portalServices, nonServiceNavPoints];
};

export function isSystemApp(application: Application) {
  return application.types.includes(SYSTEM_APP);
}

export const getAppByNamespace = <T extends AppConstraints>(appList: T[], namespace: string): T | undefined => {
  return appList.find((application: T) => application.namespace === namespace);
};

export const getCurrentNavigationPoint = (
  navPoints: NavigationPoint[],
  currentPath: string,
  preferenceLandingPage: string
): NavigationPoint | undefined => {
  let { LOCAL_MFE } = window.env;
  const [portalServices] = splitPortalNavPoints(navPoints);
  const path = LOCAL_MFE && currentPath === '/' ? portalServices[0]?.namespace : currentPath;

  const currentNamespace: string = getUrlNamespace(path);
  const defaultNamespace: string = getUrlNamespace(preferenceLandingPage);

  const preferenceApp = getAppByNamespace(navPoints, defaultNamespace);
  const defaultApplication = getAppByNamespace(navPoints, currentNamespace);

  let currentApplication;
  if ((preferenceApp && !currentNamespace) || (!defaultApplication && preferenceApp)) {
    currentApplication = preferenceApp;
  } else if (defaultApplication) {
    currentApplication = defaultApplication;
  } else if (portalServices?.length > 1) {
    [, currentApplication] = portalServices;
  }

  if (currentApplication && currentApplication.namespace) {
    if ((preferenceApp && !currentNamespace) || (!defaultApplication && preferenceApp)) {
      currentApplication.subnav = preferenceLandingPage.startsWith('/')
        ? preferenceLandingPage.replace('/' + currentApplication.namespace, '')
        : preferenceLandingPage.replace(currentApplication.namespace, '');
    } else if (defaultApplication) {
      currentApplication.subnav =
        window.location.pathname.replace('/' + currentApplication.namespace, '') +
        window.location.search +
        window.location.hash;
    } else {
      currentApplication.subnav = '/';
    }
  }

  return currentApplication;
};

export const getCurrentApp = (
  appList: Application[],
  currentPath: string,
  preferenceLandingPage: string
): Application | undefined => {
  let { LOCAL_MFE } = window.env;
  const systemApplications = getSystemApps(appList);
  currentPath = LOCAL_MFE && currentPath === '/' ? systemApplications[0]?.namespace : currentPath;

  const currentNamespace: string = getUrlNamespace(currentPath);
  const defaultNamespace: string = getUrlNamespace(preferenceLandingPage);

  const preferenceApp = getAppByNamespace(appList, defaultNamespace);
  const defaultApplication = getAppByNamespace(appList, currentNamespace);

  let currentApplication;
  if ((preferenceApp && !currentNamespace) || (!defaultApplication && preferenceApp)) {
    currentApplication = preferenceApp;
  } else if (defaultApplication) {
    currentApplication = defaultApplication;
  } else if (systemApplications?.length > 1) {
    [, currentApplication] = systemApplications;
  } else if (appList?.length > 0) {
    currentApplication = appList[0];
  } else if (LOCAL_MFE) {
    [currentApplication] = systemApplications;
  }

  if (currentApplication) {
    if ((preferenceApp && !currentNamespace) || (!defaultApplication && preferenceApp)) {
      currentApplication.subnav = preferenceLandingPage.startsWith('/')
        ? preferenceLandingPage.replace('/' + currentApplication.namespace, '')
        : preferenceLandingPage.replace(currentApplication.namespace, '');
      currentApplication.isLandingPage = true;
    } else if (defaultApplication) {
      currentApplication.subnav =
        window.location.pathname.replace('/' + currentApplication.namespace, '') +
        window.location.search +
        window.location.hash;
    } else {
      currentApplication.subnav = '/';
    }
  }

  return currentApplication;
};

export const getAppIndex = (apps: { namespace: string }[], currentApp: { namespace: string }) => {
  return apps.findIndex((app) => app.namespace === currentApp.namespace);
};

export const getNavPointIndex = (apps: { resourceId: string }[], currentApp: { resourceId: string }) => {
  return apps.findIndex((app) => app.resourceId === currentApp.resourceId);
};

export const filterSystemApps = (systemApps: Application[]) => {
  const localApps = systemApps.filter((app) => app.isLocalApp);
  return localApps.length > 0 ? localApps : systemApps;
};

const getComparableDisplayName = (
  navigationData: DisplayIndexedData | NavigationPoint,
  localizer?: ILocalizer
): string => {
  return localizer && navigationData.languagePackNamespace
    ? localizer({ key: navigationData.displayName || '', namespace: navigationData.languagePackNamespace })
    : navigationData.displayName || '';
};

export const portalStateToAssociations = (portalNavState: PortalNavigationState): NavAssociations | undefined => {
  const associations = portalNavState.selectedPortalTaskBundle?.navigationTaskBundle?.navigation?.length
    ? portalNavState.selectedPortalTaskBundle?.navigationTaskBundle?.navigation[0].associations
    : undefined;
  return associations;
};
function isNumber(value: any): boolean {
  return typeof value === 'number';
}

function compareIndexThenName(
  aIndex: number | undefined,
  bIndex: number | undefined,
  a: DisplayIndexedData | NavigationPoint,
  b: DisplayIndexedData | NavigationPoint,
  localizer: ILocalizer | undefined
): number {
  const aIndexed = isNumber(aIndex);
  const bIndexed = isNumber(bIndex);

  if (aIndexed && bIndexed && aIndex !== bIndex) {
    // Both have indices, sort numerically
    return aIndex! - bIndex!;
  } else if (aIndexed !== bIndexed) {
    // One has an index, the other doesn't. The one with an index goes first.
    return aIndexed ? -1 : 1;
  }

  const aDisplayName = getComparableDisplayName(a, localizer);
  const bDisplayName = getComparableDisplayName(b, localizer);
  return aDisplayName.localeCompare(bDisplayName);
}

export const sortOrderIndexedData = (
  data: DisplayIndexedData[] | undefined,
  localizer?: ILocalizer
): DisplayIndexedData[] => {
  return (data || []).sort((a, b) => {
    const aIndex = a.displayIndex;
    const bIndex = b.displayIndex;
    return compareIndexThenName(aIndex, bIndex, a, b, localizer);
  });
};

export const sortNavPointsByAssociations = (
  children: NavigationPoint[] | undefined,
  associations: NavAssociations | undefined,
  localizer: ILocalizer | undefined
): NavigationPoint[] => {
  return (children || []).sort((childA: NavigationPoint, childB: NavigationPoint) => {
    const aIndex: number | undefined = associations?.[childA.resourceId]?.displayIndex;
    const bIndex: number | undefined = associations?.[childB.resourceId]?.displayIndex;
    return compareIndexThenName(aIndex, bIndex, childA, childB, localizer);
  });
};

export const sortNavPointChildren = (parentNavPoint: NavigationPoint, localizer?: ILocalizer): NavigationPoint[] => {
  const { children, associations } = parentNavPoint;
  return sortNavPointsByAssociations(children, associations, localizer);
};
