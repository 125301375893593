/*
 * ===============================================================================================================
 *                                Copyright 2022-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

export type NavOrientation = 'horizontal' | 'vertical';

export interface NavigationPoint extends LocalizableDisplay {
  resourceId: string;
  realm: string;
  type: string;
  owner: string;
  enabled: boolean;
  description: string;
  namespace: string;
  frameUrl?: string;
  subnav?: string;
  moduleId?: string;
  icon?: string;
  iconColor?: string;
  headerName?: string;
  queryParams?: string;
  target?: { type: string };
  children?: NavigationPoint[];
  associations?: NavAssociations;
  interfaceId?: string;
  instance?: string;
  navigationHandler?: () => void;
  isMoreAppsItem?: boolean;
  disableDrawerToggleOnClick?: boolean;
  isFramePortalUrlExcluded?: boolean;
  orientation?: NavOrientation;
  hashLink: string;
  isRoot?: boolean;
  portalService?: false;
}

export interface NavigationInterface extends LocalizableDisplay {
  resourceId: string;
  realm: string;
  type: string;
  owner: string;
  enabled: boolean;
  frameUrl: string;
  moduleId: number;
  namespace: string;
  showNavigation?: boolean;
}

export type LocalizableDisplay = {
  displayName: string;
  languagePackNamespace?: string;
};

export interface PortalNavigationInstance extends LocalizableDisplay {
  id: string;
  namespace: string;
  realmId: string;
  contactEmail: string;
  enabled: boolean;
  defaultEntryPoint?: number;
  displayIndex?: number;
}

export interface TaskBundleDisplay extends LocalizableDisplay {
  resourceId: string;
  realm: string;
  type: string;
  owner: string;
  enabled: boolean;
  portalService: boolean;
  description: string;
  icon: string;
  iconColor: string;
  namespace: string;
  instance?: string;
  orientation?: NavOrientation;
  displayIndex?: number;
}

export interface NamespacedNavigationResource extends LocalizableDisplay {
  namespace: string;
  resourceId: string;
  realm: string;
  instance?: string;
  type: string;
  owner: string;
  enabled: boolean;
  orientation?: NavOrientation;
  isIconsInHorizontal?: boolean;
}

interface AssociationEntry {
  global?: boolean;
  realm?: boolean;
  displayIndex?: number;
  instances?: { [instanceId: string]: boolean };
}

export interface NavAssociations {
  [resource: string]: AssociationEntry;
}

export interface TaskBundleDetail {
  navigationTaskBundle: NamespacedNavigationResource & {
    defaultEntryPointLink: NavigationPoint;
    navigation: NavigationPoint[];
    associations?: NavAssociations;
  };
  interfaces: NavigationInterface[];
}

export interface NavigationSelection {
  taskBundleNamespace: string | undefined;
  interfaceNamespace: string | undefined;
}

export interface EffectiveInterfacesResponse {
  interfaces: NavigationInterface[];
}

export enum NavigationHierarchyLevel {
  GLOBAL = 'GLOBAL',
  REALM = 'REALM',
  INSTANCE = 'INSTANCE',
}

export const NAV_URL_CONSTANTS = {
  GLOBAL_PREFIX: 'lui_(G',
  REALM_PREFIX: 'lui_(R',
  INSTANCE_PREFIX: 'lui_(I',
  INTERFACE_SUFFIX: 'M)',
  TASKBUNDLE_SUFFIX: 'T)',
  INSTANCE_SUFFIX: 'S)',
};

export interface NavigationSelectionDescriptor {
  namespace: string;
  level: NavigationHierarchyLevel;
}

export type DisplayIndexedData = PortalNavigationInstance | TaskBundleDisplay;

export interface PortalNavigationSelection {
  interface?: NavigationSelectionDescriptor;
  taskBundle?: NavigationSelectionDescriptor;
  instance?: NavigationSelectionDescriptor;
  subnav?: string;
  hashLink?: string;
}
