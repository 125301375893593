/*
 * ===============================================================================================================
 *                                Copyright 2022, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

export default `Copyright © ${process.env.REACT_APP_CURRENT_YEAR} Blue Yonder Group, Inc. ALL RIGHTS RESERVED.`;
