/*
 * ===============================================================================================================
 *                                Copyright 2021, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import React, { useReducer } from 'react';
import { I18nReducer, i18nContextInitialState, I18nContext } from './index';

export const I18ContextProvider = (props: any) => {
  const [store, dispatch] = useReducer(I18nReducer, i18nContextInitialState);
  return <I18nContext.Provider value={{ store, dispatch }}>{props.children}</I18nContext.Provider>;
};
