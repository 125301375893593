/*
 * ===============================================================================================================
 *                                Copyright 2022-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import X from '@jda/lui-common-icon-library/ui/X';
import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { FunctionComponent } from 'react';
import { Tokens } from '@jda/lui-common-component-library';

export class Props {
  open: boolean;
  title: string;
  content: string;
  confirmButtonText: string;
  cancelButtonText: string;
  dataTestId?: string;
  onConfirm: () => void;
  onClose: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialog: {
      minWidth: '481px',
    },
    dialogTitle: {
      paddingLeft: '16px',
    },
    dialogContent: {
      borderBottom: 0,
      minWidth: '481px',
      padding: Tokens.spacing['$sp-4'],
    },
    actions: {
      margin: 0,
      padding: Tokens.spacing['$sp-4'],
      justifyContent: 'flex-end',
      '& button': {
        marginLeft: Tokens.spacing['$spacing-x-small'],
      },
    },
  };
});

const ConfirmationDialog: FunctionComponent<Props> = ({
  open,
  title,
  content,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onClose,
  onCancel,
  dataTestId = 'lui-portal-confirmation-dialog',
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      maxWidth="sm"
      disableBackdropClick
      onClose={onClose}
      data-testId={dataTestId}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography data-testid={`${dataTestId}-title`} variant="h5">
              {title}
            </Typography>
          </Box>
          <Box>
            <IconButton data-testid={`${dataTestId}-close-button`} size="small" aria-label="close" onClick={onClose}>
              <X />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <Typography data-testid={`${dataTestId}-content`} gutterBottom>
          {content}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          data-testid={`${dataTestId}-cancel-button`}
          variant="outlined"
          color="primary"
          disableElevation
          onClick={onCancel}
        >
          {cancelButtonText}
        </Button>
        <Button
          data-testid={`${dataTestId}-confirm-button`}
          variant="contained"
          color="primary"
          disableElevation
          onClick={onConfirm}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
