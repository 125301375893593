/*
 * ===============================================================================================================
 *                                Copyright 2020-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import React, { MouseEvent } from 'react';
import { Breadcrumbs, Link } from '@material-ui/core';

import ArrowChevronRight from '@jda/lui-common-icon-library/ui/ArrowChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { useBreadcrumbsContext } from '../../context/breadcrumbs';
import { Application } from '../../models/application.model';
import { BreadcrumbInfo } from '../../context/breadcrumbs';
import { GALLERY_APP, SYSTEM_APP, GALLERY_APP_TYPE } from '../../utils/applications';
import { useI18nContext } from '../../context/i18n';
import { ILocalizer, getFeatureFlag } from '@jda/lui-portal-utilities';
import { LocalizationFallbacks } from '@jda/lui-portal-utilities/dist/localization/localizationFactory';
import { LocalizableDisplay, NamespacedNavigationResource, NavigationPoint } from '../../models/portalNavigation.model';
import { AuthService } from '../../auth/authService';
import { usePortalApp } from '../../context/app/provider';
import { Tokens, getLogo, LuiBreadcrumbs } from '@jda/lui-common-component-library';
import { usePortalNavigation } from '../../context/portal-navigation/provider';
import { WITH_BREADCRUMBS_UPDATE } from '../../utils/constants';

interface Props {
  navPointNavigationHandler: () => void;
  onNavigateHome: () => void;
}

interface AppBreadcrumbInfo extends BreadcrumbInfo {
  applicationName: string;
}

const useStyles = makeStyles(() => {
  return {
    disabled: {
      pointerEvents: 'none',
    },
    enabled: {
      cursor: 'pointer',
    },
    iconLink: {
      cursor: 'pointer',
      marginRight: Tokens.spacing['$spacing-x-small'],
    },
    breacrumbsList: {
      maxHeight: '24px',
    },
    breadcrumbs: {
      '& li:nth-child(2)': {
        display: 'none',
      },
    },
    separator: {
      marginLeft: '0',
      marginRight: '0',
    },
  };
});

/**
 * Render breadcrumbs component with navigation functionality
 * @return Breadcrumbs React component
 */
export const HeaderBreadcrumbs = function ({ navPointNavigationHandler, onNavigateHome }: Props) {
  const classes = useStyles();
  const { breadcrumbs } = useBreadcrumbsContext();
  const { appState, handlePortalNavigation, updateCurrentApplication } = usePortalApp();
  // @ts-ignore
  const types = appState.currentApplication?.types || [];
  const appBreadcrumbs = React.useMemo(
    () =>
      breadcrumbs.map((crumb) => {
        return {
          ...crumb,
          applicationName: appState.currentApplication?.namespace,
        };
      }),
    [breadcrumbs, appState.currentApplication?.namespace]
  );

  const { portalNavState } = usePortalNavigation();

  const { localizer } = useI18nContext().store;

  const isBreadCrumbUpdate = getFeatureFlag(WITH_BREADCRUMBS_UPDATE);

  const showLogo = appState.useHierarchicalNavigation && !isBreadCrumbUpdate;

  const BreadcrumbComponent = isBreadCrumbUpdate ? LuiBreadcrumbs : Breadcrumbs;

  const navigate = (event: MouseEvent, disabled: boolean, url: string, applicationName: string) => {
    const applications: Array<Application | NavigationPoint> = appState.useHierarchicalNavigation
      ? appState.navPoints
      : appState.applicationList;
    const application = applications.find(({ namespace }) => {
      return namespace === applicationName;
    });
    if (application === undefined || disabled) {
      event.preventDefault();
    } else {
      handlePortalNavigation(application, url);
    }
  };

  let currentAppBreadcrumb = appState.currentApplication
    ? {
        pathname: `/${appState.currentApplication.namespace}`,
        title: generateTitle(appState.currentApplication, localizer),
        applicationName: appState.currentApplication.namespace,
      }
    : undefined;

  // Determine portal breadcrumbs by application type(s)
  let firstBreadcrumb;
  if (!types.includes(SYSTEM_APP) && types.includes(GALLERY_APP_TYPE)) {
    // @ts-ignore
    const appGallery = appState.systemApplications.find(({ namespace }) => namespace === GALLERY_APP);
    firstBreadcrumb = appGallery
      ? {
          pathname: '',
          title: generateTitle(appGallery, localizer),
          applicationName: appGallery.namespace,
          localizer,
        }
      : firstBreadcrumb;
  }

  const portalBreadcrumbs = appBreadcrumbs.length === 0 ? [firstBreadcrumb, currentAppBreadcrumb] : [firstBreadcrumb];

  const getInstanceTaskBundleTitle = (): AppBreadcrumbInfo | undefined => {
    let displayInstanceTaskBundleName: string | undefined;
    if (portalNavState.selectedPortalTaskBundle) {
      displayInstanceTaskBundleName = portalNavState.selectedPortalTaskBundle?.navigationTaskBundle
        ? generateTitle(portalNavState.selectedPortalTaskBundle.navigationTaskBundle, localizer)
        : '';
    }

    if (
      displayInstanceTaskBundleName &&
      portalNavState.selectedPortalInstance &&
      portalNavState.portalInstances &&
      portalNavState.portalInstances.length
    ) {
      const displayInstanceName = portalNavState.selectedPortalInstance
        ? generateTitle(portalNavState.selectedPortalInstance, localizer)
        : '';
      displayInstanceTaskBundleName = displayInstanceName + ` - ${displayInstanceTaskBundleName}`;
    }

    if (displayInstanceTaskBundleName) {
      const namespace = portalNavState.selectedPortalTaskBundle?.navigationTaskBundle.defaultEntryPointLink?.namespace;
      return { pathname: '/', title: displayInstanceTaskBundleName, applicationName: namespace || '' };
    }
    return undefined;
  };

  const allBreadcrumbs = [...portalBreadcrumbs, ...appBreadcrumbs].filter((crumb) => !!crumb) as AppBreadcrumbInfo[];
  const accountInfo = AuthService.getInstance().getDefaultAccount();
  const realmTitle = (accountInfo?.idTokenClaims as any)?.by_realm;
  const realm = { pathname: '/', title: realmTitle, applicationName: '' };
  if (appState.useHierarchicalNavigation) {
    const instanceTaskBundleTitle = getInstanceTaskBundleTitle();
    if (instanceTaskBundleTitle) {
      allBreadcrumbs.unshift(instanceTaskBundleTitle);
    }
  }
  if (realmTitle) {
    allBreadcrumbs.unshift(realm);
  }
  const links = allBreadcrumbs.map(({ pathname, title, applicationName }, index, array) => {
    const disabled = index === array.length - 1 || (index === 0 && !isBreadCrumbUpdate);
    return (
      <Link
        key={`${pathname}//${applicationName}`}
        color="inherit"
        onClick={(e: MouseEvent) => (index === 0 ? onNavigateHome() : navigate(e, disabled, pathname, applicationName))}
        className={disabled ? `${classes.disabled}` : `${classes.enabled}`}
        data-testid="luminate-portal-breadcrumbs-item"
        component="button"
      >
        {title}
      </Link>
    );
  });

  return (
    <BreadcrumbComponent
      separator={<ArrowChevronRight fontSize="small" />}
      data-testid="luminate-breadcrumbs"
      data-tlm-name="luminate-portal-breadcrumbs"
      aria-label="breadcrumb"
      className={showLogo ? classes.breadcrumbs : ''}
      classes={{ li: classes.breacrumbsList, separator: classes.separator }}
    >
      {showLogo && (
        <Link
          data-testid="luminate-portal-breadrcrumb-home-link"
          color="inherit"
          onClick={navPointNavigationHandler}
          className={classes.iconLink}
          component="button"
        >
          {getLogo(false, appState.logo, 'luminate-toggle-drawer-button-logo')}
        </Link>
      )}

      {links}
    </BreadcrumbComponent>
  );
};

/**
 * Return correct value for breadcrumb title
 * @return Display title in breadcrumbs
 */
export function generateTitle(application: Application | NavigationPoint | LocalizableDisplay, localizer?: ILocalizer) {
  const displayValue = (application as Application | NavigationPoint).headerName || application.displayName || '';
  let fallback: LocalizationFallbacks = {};
  // use an empty rather than key if value is not found because this will be very visible in the header
  if (application.languagePackNamespace && localizer) {
    fallback[application.languagePackNamespace] = {};
    fallback[application.languagePackNamespace][displayValue] = ' ';
    console.debug(
      `stored header name for key ${displayValue}: ${localizer({
        key: displayValue,
        namespace: application.languagePackNamespace,
      })}`
    );
  }

  return application.languagePackNamespace && localizer
    ? localizer({ key: displayValue, namespace: application.languagePackNamespace, valueFallbacks: fallback })
    : displayValue;
}

export default HeaderBreadcrumbs;
