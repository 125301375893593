/*
 * ===============================================================================================================
 *                                Copyright 2022-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { fetchData } from '@jda/lui-portal-utilities';
import { AuthService } from '../auth/authService';
import stringify from 'json-stringify-safe';
import axios from 'axios';
import { Logo } from '@jda/lui-common-component-library/dist/components/luiDrawer/types';

export interface LogoResponseModel {
  filename: string;
  realm: string;
  configurationName: string;
  contentType: string;
  url: string;
}

export interface LogoResponseObject {
  expanded: LogoResponseModel;
  collapsed: LogoResponseModel;
}
const { REGISTRATION_URL } = window['env'];
const REGISTRATION_SERVICE_LOGO_SUB_URL = `${REGISTRATION_URL}/logos`;

const convertBlobToBase64 = async (blob: Blob): Promise<string> => {
  let base64 = await new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = (e) => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
  return base64 as string;
};

export const getLogos = async (): Promise<Logo | undefined> => {
  try {
    const response = await fetchData<LogoResponseObject>({
      url: `${REGISTRATION_SERVICE_LOGO_SUB_URL}`,
      tokenProvider: AuthService.getInstance().getAuthToken,
    });
    const resolved = Promise.all([
      axios.get<string>(response.collapsed.url, { responseType: 'blob' }),
      axios.get<string>(response.expanded.url, { responseType: 'blob' }),
    ])
      .then(async (logoRes) => {
        const logoBlob: Blob[] = [];

        for (let i = 0; i < logoRes.length; i++) {
          logoBlob[i] = new Blob([logoRes[i].data], { type: response.collapsed.contentType });
        }

        let logos: Logo = {
          std: (await convertBlobToBase64(logoBlob[1])) as string,
          type: response.collapsed.contentType,
          thumbnail: (await convertBlobToBase64(logoBlob[0])) as string,
        };

        return logos;
      })
      .catch(() => {
        return undefined;
      });
    return resolved;
  } catch (error) {
    console.error(`[Logo Service] - getLogos Error: ${stringify(error)}`);
    return undefined;
  }
};
