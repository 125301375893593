/*
 * ===============================================================================================================
 *                                Copyright 2023, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import React, { createContext, useContext, useState } from 'react';
import { DrawerVariants } from '@jda/lui-common-component-library';

interface Props {
  children: React.ReactNode;
}

export interface ApplicationDrawerValues {
  openFolderId: string;
  isChildDrawerPinned: boolean;
  drawerOpen: boolean;
  childDrawerOpen: boolean;
  showDrawer: boolean;
  handleOpenFolder: (openFolderId: string) => void;
  handleIsChildDrawerPinned: (pinned: boolean) => void;
  updateActiveNavPoint: (idx: number) => void;
  isActiveApp: (idx: number) => boolean;
  toggleDrawer: (state?: boolean) => void;
  closeDrawer: () => void;
  closeChildDrawer: () => void;
  openChildDrawer: () => void;
  hideDrawer: () => void;
  visibilizeDrawer: () => void;
  setDrawerVariant: () => DrawerVariants.Collapsible | DrawerVariants.Flyout;
}

export const applicationDrawerInitialState: ApplicationDrawerValues = {
  openFolderId: '',
  isChildDrawerPinned: false,
  drawerOpen: false,
  childDrawerOpen: false,
  showDrawer: false,
  handleOpenFolder: () => {},
  handleIsChildDrawerPinned: () => {},
  updateActiveNavPoint: () => {},
  isActiveApp: () => false,
  toggleDrawer: () => {},
  closeDrawer: () => {},
  closeChildDrawer: () => {},
  openChildDrawer: () => {},
  hideDrawer: () => {},
  visibilizeDrawer: () => {},
  setDrawerVariant: () => DrawerVariants.Collapsible,
};

function DrawerProvider(props: Props) {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [openFolderId, setOpenFolderId] = useState<string>('');
  const [isChildDrawerPinned, setIsChildDrawerPinned] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [childDrawerOpen, setChildDrawerOpen] = React.useState(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const handleOpenFolder = React.useCallback((openFolderId: string) => {
    setOpenFolderId(openFolderId);
  }, []);

  const handleIsChildDrawerPinned = React.useCallback((pinned: boolean) => {
    setIsChildDrawerPinned(pinned);
  }, []);

  const updateActiveNavPoint = React.useCallback((idx: number): void => {
    setCurrentTab(idx);
  }, []);

  const isActiveApp = React.useCallback(
    (idx: number): boolean => {
      return idx === currentTab;
    },
    [currentTab]
  );

  const toggleDrawer = React.useCallback(
    (state?: boolean) => {
      setDrawerOpen(state !== undefined ? state : drawerOpen);
    },
    [drawerOpen]
  );

  const closeDrawer = React.useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const closeChildDrawer = React.useCallback(() => {
    setChildDrawerOpen(false);
  }, []);

  const openChildDrawer = React.useCallback(() => {
    setChildDrawerOpen(true);
  }, []);

  const hideDrawer = React.useCallback(() => {
    if (showDrawer) setShowDrawer(false);
  }, [showDrawer]);

  const visibilizeDrawer = React.useCallback(() => {
    if (!showDrawer) setShowDrawer(true);
  }, [showDrawer]);

  const setDrawerVariant = React.useCallback(() => {
    if (showDrawer) {
      return DrawerVariants.Collapsible;
    } else {
      return DrawerVariants.Flyout;
    }
  }, [showDrawer]);

  const contextValue = React.useMemo(() => {
    return {
      openFolderId,
      isChildDrawerPinned,
      drawerOpen,
      childDrawerOpen,
      showDrawer,
      handleOpenFolder,
      handleIsChildDrawerPinned,
      updateActiveNavPoint,
      isActiveApp,
      toggleDrawer,
      closeDrawer,
      hideDrawer,
      visibilizeDrawer,
      setDrawerVariant,
      closeChildDrawer,
      openChildDrawer,
    };
  }, [
    openFolderId,
    isChildDrawerPinned,
    drawerOpen,
    childDrawerOpen,
    showDrawer,
    handleOpenFolder,
    handleIsChildDrawerPinned,
    updateActiveNavPoint,
    isActiveApp,
    toggleDrawer,
    closeDrawer,
    hideDrawer,
    visibilizeDrawer,
    setDrawerVariant,
    closeChildDrawer,
    openChildDrawer,
  ]);
  return <DrawerContext.Provider value={contextValue}>{props.children}</DrawerContext.Provider>;
}

export const DrawerContext = createContext<ApplicationDrawerValues>(applicationDrawerInitialState);

export const usePortalDrawer = () => useContext(DrawerContext);

export default DrawerProvider;
