/*
 * ===============================================================================================================
 *                                Copyright 2023-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { BreadcrumbInfo, PortalHelpUpdateParameter, Preferences } from '@jda/lui-portal-utilities';
import {
  InitializePayload,
  SetBackdropPayload,
  UpdateCurrentApplicationPayload,
  UpdatePayload,
} from '../../../models/App';
import { SettingsDictionary } from '../../../models/applicationSettings.model';
import BaseAction from '../../../models/BaseAction';

export enum AppActionType {
  INITIALIZE = '[App] Initialize',
  UPDATE_CURRENT_APPLICATION = '[App] Update current application',
  UPDATE = '[App] Update',
  SET_USER_PREFERENCES = '[App] Set user preferences',
  SET_THEME = '[App] Set theme',
  SET_THEME_SELECTION = '[App] Set theme selection',
  SET_BREADCRUMBS = '[App] Set breadcrumbs',
  SET_WAKELOCK = '[App] Set wakelock',
  SET_BACKDROP = '[App] Set backdrop',
  SET_SETTINGS_DATA = '[App] Set settings data',
  SET_NOTIFICATION_SOURCE_CACHE_REFRESH = '[App] Set notification source cache refresh',
  SET_PORTAL_TITLE = '[App] Set portal title',
  SET_HELP_ADDRESS = '[App] Set help address',
}

export type AppAction = BaseAction<AppActionType>;

export class Initialize implements AppAction {
  readonly type = AppActionType.INITIALIZE;
  readonly payload: InitializePayload;

  constructor(data: InitializePayload) {
    this.payload = data;
  }
}

export class UpdateCurrentApplication implements AppAction {
  readonly type = AppActionType.UPDATE_CURRENT_APPLICATION;
  readonly payload: UpdateCurrentApplicationPayload;

  constructor(data: UpdateCurrentApplicationPayload) {
    this.payload = data;
  }
}
export class Update implements AppAction {
  readonly type = AppActionType.UPDATE;
  readonly payload: UpdatePayload;

  constructor(data: UpdatePayload) {
    this.payload = data;
  }
}

export class SetUserPreferences implements AppAction {
  readonly type = AppActionType.SET_USER_PREFERENCES;
  readonly payload: Preferences | undefined;

  constructor(prefs: Preferences | undefined) {
    this.payload = prefs;
  }
}

export class SetTheme implements AppAction {
  readonly type = AppActionType.SET_THEME;
  readonly payload: string;

  constructor(theme: string) {
    this.payload = theme;
  }
}

export class SetThemeSelection implements AppAction {
  readonly type = AppActionType.SET_THEME_SELECTION;
  readonly payload: string;

  constructor(themeSelection: string) {
    this.payload = themeSelection;
  }
}

export class SetBreadcrumbs implements AppAction {
  readonly type = AppActionType.SET_BREADCRUMBS;
  readonly payload: BreadcrumbInfo[];

  constructor(breadcrumbs: BreadcrumbInfo[]) {
    this.payload = breadcrumbs;
  }
}

export class SetWakelock implements AppAction {
  readonly type = AppActionType.SET_WAKELOCK;
  readonly payload: boolean;

  constructor(wakelockEnabled: boolean) {
    this.payload = wakelockEnabled;
  }
}

export class SetBackdrop implements AppAction {
  readonly type = AppActionType.SET_BACKDROP;
  readonly payload: SetBackdropPayload;

  constructor(data: SetBackdropPayload) {
    this.payload = data;
  }
}

export class SetSettingsData implements AppAction {
  readonly type = AppActionType.SET_SETTINGS_DATA;
  readonly payload: SettingsDictionary | undefined;

  constructor(settingsData: SettingsDictionary | undefined) {
    this.payload = settingsData;
  }
}

export class SetSourceCacheRefresh implements AppAction {
  readonly type = AppActionType.SET_NOTIFICATION_SOURCE_CACHE_REFRESH;
  readonly payload: boolean;

  constructor(isSourceRefresh: boolean) {
    this.payload = isSourceRefresh;
  }
}

export class SetPortalHelpAddress implements AppAction {
  readonly type = AppActionType.SET_HELP_ADDRESS;
  readonly payload: PortalHelpUpdateParameter;

  constructor(helpUpdateParameter: PortalHelpUpdateParameter) {
    this.payload = helpUpdateParameter;
  }
}

export class SetPortalTitle implements AppAction {
  readonly type = AppActionType.SET_PORTAL_TITLE;
  readonly payload: string;

  constructor(title: string) {
    this.payload = title;
  }
}

type Action =
  | Initialize
  | Update
  | UpdateCurrentApplication
  | SetUserPreferences
  | SetTheme
  | SetThemeSelection
  | SetBreadcrumbs
  | SetWakelock
  | SetBackdrop
  | SetSettingsData
  | SetSourceCacheRefresh
  | SetPortalTitle
  | SetPortalHelpAddress;

export default Action;
