/*
 * ===============================================================================================================
 *                                Copyright 2021-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { DarkTheme, LightTheme, UltraLightTheme } from '@jda/lui-common-component-library';

const { REGISTRATION_URL } = window['env'];

export const I18N_NAMESPACE: string = 'plat.lui.portal';
export const LOCALIZE_TEMPLATE = { namespace: I18N_NAMESPACE };
export const PREFERENCES_APP = 'plat.lui.portal';
export const DASHBOARD_SCAFFOLDING_I18N_NAMESPACE = 'plat.lui.scaffolding';

export const PREFERENCES_AVATAR_SETTINGS_APP = 'portal.avatarsettings';

export const PREFERENCES_AVATAR_SETTINGS_LANGUAGE_FIELD = 'languageOptions';

export const PREFERENCES_FIELDS = {
  LANGUAGE: 'language',
  REGION: 'region',
  NOTIFICATIONS_SOURCE_CONFIGURATION_KEY: 'notificationsSourceConfigurationKey',
};
export const LOCAL_PORTAL_CONFIGURATION_PLACEHOLDER = 'LOCAL_PORTAL_CONFIGURATION_PLACEHOLDER';
export const IFRAME_ZINDEX = 1100;
export const IFRAME_ZINDEX_OFFSET = 1100;
export const IFRAME_PORTAL_URL = 'portal-host';
export const BACKDROP_ZINDEX_OFFSET = 1000;
export const NOTIFICATIONS_DRAWER_WIDTH = 432;
export const NOTIFICATIONS_DEFAULT_POLLING_FREQUENCY = 10000;
export const LOCAL_STORAGE_DOMAIN_HINT_NAME = 'liam-domain-hint';
export const LOCAL_STORAGE_LOGIN_ERROR = 'custom-login-error';
export const LOCAL_STORAGE_TIMEOUT_OPT_IN = 'timeout-was-actived';
export const LOCAL_STORAGE_LAST_CONNECTION = 'last-connection';

export const THEME_STORAGE_KEY: string = 'BYTheme';
export const THEME_SELECTION_STORAGE_KEY: string = 'BYThemeSelection';
export const DEFAULT_THEME: string = 'light';
export const THEMES = { dark: DarkTheme, light: LightTheme };
export const THEMES_V2 = {
  light: UltraLightTheme,
  dark: DarkTheme,
};
export const registrationUrl: string = REGISTRATION_URL ? REGISTRATION_URL.trim() : '';
export const DEFAULT_INACTIVITY_TIMEOUT = 3600;
export const DEFAULT_TIMEOUT_WARNING = 120;
export enum SETTINGS_TAB_PANEL_VALUE {
  LANGUAGE_TAB = '0',
  NOTIFICATION_SOURCE = '1',
  ABOUT_TAB = '2',
}

export const PORTAL_FRAME_TITLE = 'Blue Yonder Portal';
export const PORTAL_FRAME_TITLE_LEGACY = 'Luminate Portal';

export enum PortalHelpLaunchOptions {
  LEGACY = 'LEGACY',
  NEW_TAB = 'NEW_TAB',
  SHARED_TAB = 'SHARED_TAB',
}

export enum COOKIES_PREFERENCES_OPTIONS {
  CUSTOM = 'custom',
  ALLOW_ALL = 'allow-all',
  ALLOW_NECESSARY = 'allow-necessary',
}

export const PORTAL_HELP_ADDRESS_DEFAULT = window.env.HELP_HOME_ADDRESS || 'https://success.blueyonder.com';

// Feature Flags
export const WITH_PORTAL_HIERARCHICAL_NAVIGATION = 'featrel_plat_lui_LUI11258';
export const WITH_NOTIFICATION_DRAWER_NAVIGATION = 'featrel_plat_lui_LUI14339';
export const WITH_NOTIFICATION_ADVANCED_FILTER = 'featrel_plat_lui_LUI12540';
export const WITH_NOTIFICATION_EMAIL_SUBSCRIPTION = 'featrel_plat_lui_LUI11672';
export const WITH_TIMEOUT_OPT_ON_CLOSE_TABS = 'featrel_plat_lui_LUI19230';
export const WITH_BREADCRUMBS_UPDATE = 'featrel_plat_lui_LUI19965';
export const WITH_LIGHT_THEME_UPDATE = 'featrel_plat_lui_LUI19964';
export const WITH_TITLE_UPDATE = 'featrel_plat_lui_LUI10648';
export const WITH_PORTAL_HELP = 'featrel_plat_lui_LUI19489';
export const WITH_COOKIES_OPT_IN = 'featrel_plat_lui_LUI18355';
