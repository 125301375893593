/*
 * ===============================================================================================================
 *                                Copyright 2023-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import React, { createContext, useContext, useReducer, useState } from 'react';
import { CustomAvatarMenu, HeaderItem } from '@jda/lui-portal-utilities';
import appBarReducer, { ApplicationBarState, initialApplicationBarState } from './store/reducer';
import {
  AppBarAction,
  SetAIAssistantMenu,
  SetCollaborationMenu,
  SetCustomAvatarMenu,
  SetCustomHeaderItems,
  SetHamburgerMenu,
  SetIsLoading,
  SetNotificationsMenu,
  ClearAppBarCustomizations,
} from './store/actions';

interface Props {
  children: React.ReactNode;
}

export interface IconDisplayState {
  isActive: boolean;
}

export interface NotificationIconDisplayState extends IconDisplayState {
  count: number;
}

interface ApplicationBarContextValues {
  appBarState: ApplicationBarState;
  appBarDispatch: React.Dispatch<AppBarAction>;
  handleSetIsLoadingApplication: (isLoading: boolean) => void;
  handleNotificationsDisplay: (displayState: NotificationIconDisplayState) => void;
  handleCollaborationDisplay: (displayState: IconDisplayState) => void;
  handleAIAssistantDisplay: (displayState: IconDisplayState) => void;
  handleHamburgerDisplay: (displayState: IconDisplayState) => void;
  handleCustomAvatarMenu: (customAvatarMenu: CustomAvatarMenu) => void;
  handleCustomHeaderItems: (customHeaderItems: HeaderItem[]) => void;
  handleClearAppBarCustomizations: () => void;
}

function ApplicationBarProvider(props: Props) {
  const [appBarState, appBarDispatch] = useReducer(appBarReducer, initialApplicationBarState);

  const handleSetIsLoadingApplication = React.useCallback(
    (isLoading: boolean): void => {
      appBarDispatch(new SetIsLoading(isLoading));
    },
    [appBarDispatch]
  );

  const handleNotificationsDisplay = React.useCallback(
    (notificationDisplayState: NotificationIconDisplayState): void => {
      appBarDispatch(new SetNotificationsMenu(notificationDisplayState));
    },
    [appBarDispatch]
  );

  const handleCollaborationDisplay = React.useCallback(
    (iconState: IconDisplayState): void => {
      appBarDispatch(new SetCollaborationMenu(iconState));
    },
    [appBarDispatch]
  );

  const handleAIAssistantDisplay = React.useCallback(
    (iconState: IconDisplayState): void => {
      appBarDispatch(new SetAIAssistantMenu(iconState));
    },
    [appBarDispatch]
  );

  const handleHamburgerDisplay = React.useCallback(
    (iconState: IconDisplayState): void => {
      appBarDispatch(new SetHamburgerMenu(iconState));
    },
    [appBarDispatch]
  );

  const handleCustomAvatarMenu = React.useCallback(
    (customAvatarMenu: CustomAvatarMenu): void => {
      appBarDispatch(new SetCustomAvatarMenu(customAvatarMenu));
    },
    [appBarDispatch]
  );

  const handleCustomHeaderItems = React.useCallback(
    (customHeaderItems: HeaderItem[]): void => {
      appBarDispatch(new SetCustomHeaderItems(customHeaderItems));
    },
    [appBarDispatch]
  );

  const handleClearAppBarCustomizations = React.useCallback((): void => {
    appBarDispatch(new ClearAppBarCustomizations());
  }, [appBarDispatch]);

  const contextValue = React.useMemo(() => {
    return {
      appBarState,
      appBarDispatch,
      handleSetIsLoadingApplication,
      handleNotificationsDisplay,
      handleCollaborationDisplay,
      handleAIAssistantDisplay,
      handleHamburgerDisplay,
      handleCustomAvatarMenu,
      handleCustomHeaderItems,
      handleClearAppBarCustomizations,
    };
  }, [
    appBarState,
    appBarDispatch,
    handleSetIsLoadingApplication,
    handleNotificationsDisplay,
    handleCollaborationDisplay,
    handleAIAssistantDisplay,
    handleHamburgerDisplay,
    handleCustomAvatarMenu,
    handleCustomHeaderItems,
    handleClearAppBarCustomizations,
  ]);
  // @ts-ignore
  return <ApplicationBarContext.Provider value={contextValue}>{props.children}</ApplicationBarContext.Provider>;
}

export const ApplicationBarContext = createContext<ApplicationBarContextValues>({
  appBarState: initialApplicationBarState,
  appBarDispatch: () => {},
  handleSetIsLoadingApplication: () => {},
  handleNotificationsDisplay: () => {},
  handleCollaborationDisplay: () => {},
  handleAIAssistantDisplay: () => {},
  handleHamburgerDisplay: () => {},
  handleCustomAvatarMenu: () => {},
  handleCustomHeaderItems: () => {},
  handleClearAppBarCustomizations: () => {},
});

export const usePortalApplicationBar = () => useContext(ApplicationBarContext);

export default ApplicationBarProvider;
