/*
 * ===============================================================================================================
 *                                Copyright 2022-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import axios, { AxiosResponse } from 'axios';
import { Field } from '../models/FormBuilder';
import { fetchData } from '@jda/lui-portal-utilities';
import stringify from 'json-stringify-safe';
import { AuthService } from '../auth/authService';
import {
  NavigationInterface,
  PortalNavigationInstance,
  TaskBundleDetail,
  TaskBundleDisplay,
  EffectiveInterfacesResponse,
} from '../models/portalNavigation.model';
import { registrationUrl } from '../utils/constants';
import { sortOrderIndexedData } from '../utils/applications';
interface GetRegistrationFormResponse {
  realm: string;
  user: string;
  fields: Field[];
}

const portalNavigationServiceEndpoints = {
  getEnabledPortalInstancesList: `${registrationUrl}/portalInstances`,
  getTaskBundles: (instanceNamespace?: string) =>
    `${registrationUrl}/navigation/displayNavigationTaskBundles` +
    `${instanceNamespace ? `?instance=${instanceNamespace}` : ''}`,
  getTaskBundle: (namespace: string, instanceNamespace: string | undefined) =>
    `${registrationUrl}/navigation/effectiveNavigationTaskBundles/${namespace}` +
    `${instanceNamespace ? `?instance=${instanceNamespace}` : ''}`,
  getEffectiveInterfaces: (instanceNamespace: string | undefined) =>
    `${registrationUrl}/navigation/effectiveNavigationInterfaces` +
    `${instanceNamespace ? `?instance=${instanceNamespace}` : ''}`,
};

export class RegistrationService {
  private static instance: RegistrationService;

  public static getInstance(): RegistrationService {
    if (!RegistrationService.instance) {
      RegistrationService.instance = new RegistrationService();
    }

    return RegistrationService.instance;
  }

  getEnabledPortalInstancesList = async (): Promise<PortalNavigationInstance[]> => {
    try {
      const enabledPortalInstancesList = await fetchData<PortalNavigationInstance[]>({
        url: portalNavigationServiceEndpoints.getEnabledPortalInstancesList,
        tokenProvider: AuthService.getInstance().getAuthToken,
      });

      return sortOrderIndexedData(enabledPortalInstancesList) as PortalNavigationInstance[];
    } catch (error) {
      console.error(`[Registration Service] - getItemDefaultValue Error: ${stringify(error)}`);
      throw error;
    }
  };

  getTaskBundles = async (instanceNamespace?: string): Promise<TaskBundleDisplay[]> => {
    try {
      const taskBundles = await fetchData<TaskBundleDisplay[]>({
        url: portalNavigationServiceEndpoints.getTaskBundles(instanceNamespace),
        tokenProvider: AuthService.getInstance().getAuthToken,
      });

      return sortOrderIndexedData(taskBundles) as TaskBundleDisplay[];
    } catch (error) {
      console.error(`[Registration Service] - getTaskBundles Error: ${stringify(error)}`);
      throw error;
    }
  };

  getTaskBundle = async (namespace: string, instanceNamespace: string | undefined): Promise<TaskBundleDetail> => {
    try {
      const taskBundle = await fetchData<TaskBundleDetail>({
        url: portalNavigationServiceEndpoints.getTaskBundle(namespace, instanceNamespace),
        tokenProvider: AuthService.getInstance().getAuthToken,
      });

      return taskBundle;
    } catch (error) {
      console.error(`[Registration Service] - getTaskBundle Error: ${stringify(error)}`);
      throw error;
    }
  };

  getEffectiveInterfaces = async (instanceNamespace: string | undefined): Promise<EffectiveInterfacesResponse> => {
    try {
      const effectiveInterfaces = await fetchData<EffectiveInterfacesResponse>({
        url: portalNavigationServiceEndpoints.getEffectiveInterfaces(instanceNamespace),
        tokenProvider: AuthService.getInstance().getAuthToken,
      });

      return effectiveInterfaces;
    } catch (error) {
      console.error(`[Registration Service] - getEffectiveInterfaces Error: ${stringify(error)}`);
      throw error;
    }
  };
}

export default RegistrationService.getInstance();
