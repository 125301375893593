/*
 * ===============================================================================================================
 *                                Copyright 2023, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import React, { useContext, createContext, useReducer, useMemo } from 'react';
import globalUIComponentsReducer, {
  ConfirmationDialogOptions,
  GlobalUIComponentsState,
  initialGlobalUIComponentsState,
} from './store/reducer';
import Snackbar from '../../components/snackbar/snackbar';
import { Message } from '../../models/Message';
import GlobalUIComponentsAction, { PopMessage, SetConfirmationDialog } from './store/actions';
import ConfirmationDialog from '../../components/confirmationDialog/ConfirmationDialog';

export interface GlobalUIComponentsContextValues {
  globalUIComponentsState: GlobalUIComponentsState;
  dispatchGlobalUIComponentsState: React.Dispatch<GlobalUIComponentsAction>;
  showConfirmationDialog: (options: ConfirmationDialogOptions) => Promise<Boolean>;
}

interface Props {
  children: React.ReactNode;
}

function GlobalUIComponentsProvider(props: Props) {
  const [globalUIComponentsState, dispatchGlobalUIComponentsState] = useReducer(
    globalUIComponentsReducer,
    initialGlobalUIComponentsState
  );
  const resolvePromiseRef = React.useRef<(value: Boolean) => void>();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [currentSnackbarMessage, setCurrentSnackbarMessage] = React.useState<Message>({
    message: '',
    severity: 'info',
  });
  const { messageQueue } = globalUIComponentsState;

  React.useEffect(() => {
    if (messageQueue.length) {
      setCurrentSnackbarMessage({ ...messageQueue[0] });
      dispatchGlobalUIComponentsState(new PopMessage());
      setSnackbarOpen(true);
    }
  }, [messageQueue, currentSnackbarMessage, snackbarOpen]);

  const handleSnackbarClose = React.useCallback(
    (_event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    },
    [setSnackbarOpen]
  );

  const showConfirmationDialog = React.useCallback(
    (options: ConfirmationDialogOptions) => {
      dispatchGlobalUIComponentsState(new SetConfirmationDialog({ ...options, open: true }));
      return new Promise<Boolean>((resolve) => {
        resolvePromiseRef.current = resolve;
      });
    },
    [dispatchGlobalUIComponentsState]
  );

  const handleDialogResponse = React.useCallback(
    (response: boolean) => {
      if (resolvePromiseRef.current) {
        resolvePromiseRef.current(response);
      }
      dispatchGlobalUIComponentsState(
        new SetConfirmationDialog({ ...globalUIComponentsState.confirmationDialogState, open: false })
      );
    },
    [globalUIComponentsState.confirmationDialogState, dispatchGlobalUIComponentsState]
  );

  const contextValue: GlobalUIComponentsContextValues = useMemo(() => {
    return {
      globalUIComponentsState,
      dispatchGlobalUIComponentsState,
      showConfirmationDialog,
    };
  }, [globalUIComponentsState, dispatchGlobalUIComponentsState, showConfirmationDialog]);

  return (
    <GlobalUIComponentsContext.Provider value={contextValue}>
      {props.children}
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={currentSnackbarMessage?.message}
        severity={currentSnackbarMessage?.severity}
      />
      <ConfirmationDialog
        onConfirm={() => handleDialogResponse(true)}
        onClose={() => handleDialogResponse(false)}
        onCancel={() => handleDialogResponse(false)}
        {...globalUIComponentsState.confirmationDialogState}
      />
    </GlobalUIComponentsContext.Provider>
  );
}

const GlobalUIComponentsContext = createContext<GlobalUIComponentsContextValues>({
  globalUIComponentsState: initialGlobalUIComponentsState,
  dispatchGlobalUIComponentsState: () => {},
  showConfirmationDialog: Promise.reject,
});

export const useGlobalUIComponents = () => useContext(GlobalUIComponentsContext);

export default GlobalUIComponentsProvider;
