/*
 * ===============================================================================================================
 *                                Copyright 2021-2023, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { AuthService } from '../auth/authService';
import { fetchData } from '@jda/lui-portal-utilities';
import { DEFAULT_INACTIVITY_TIMEOUT, DEFAULT_TIMEOUT_WARNING } from './constants';
const { REALM_SERVICE } = window['env'];

export interface RealmSettings {
  id: number;
  inactivityTimeout: number;
  realmId: string;
  timeoutWarning: number;
}

export const getRealmSettings = async (): Promise<RealmSettings> => {
  const endpoint = `${REALM_SERVICE}/realmSettings`;
  try {
    let res = await fetchData<any>({ url: endpoint, tokenProvider: AuthService.getInstance().getAuthToken });
    return res;
  } catch (e) {
    const INACTIVITY_TIMEOUT = (window.env.INACTIVITY_TIMEOUT as number) || DEFAULT_INACTIVITY_TIMEOUT;
    return { id: 0, inactivityTimeout: INACTIVITY_TIMEOUT, realmId: '', timeoutWarning: DEFAULT_TIMEOUT_WARNING };
  }
};
