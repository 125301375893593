/*
 * ===============================================================================================================
 *                                Copyright 2020-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, SeverityLevel } from '@microsoft/applicationinsights-web';
import ScreenSizeDetector from 'screen-size-detector';
import { AuthService } from '../auth/authService';
import history from '../service/history';
import { ITelemetryItem } from '@microsoft/applicationinsights-core-js';
import { Application } from '../models/application.model';
import { SessionService } from '../auth/sessionService';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { NavigationPoint } from '../models/portalNavigation.model';

interface AiAppsData {
  application: Application | NavigationPoint;
  subnav: string | undefined;
}
let aiAppsData: AiAppsData;
const setAiAppsData = function (_aiAppsData: AiAppsData) {
  console.log('set app insight data');
  console.log(aiAppsData);
  aiAppsData = _aiAppsData;
};
const screenDetected = { ...new ScreenSizeDetector() };
const screen = {
  width: screenDetected.width,
  height: screenDetected.height,
  is: screenDetected.is,
};

const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true,
  urlCollectHash: true,
  urlCollectQuery: true,
  dropInvalidEvents: true,
  dataTags: {
    customDataPrefix: 'data-tlm-',
    aiBlobAttributeTag: 'ai-blob',
    parentDataTag: 'parent-group',
    useDefaultContentNameOrId: true,
  },
};
const { LOCAL_APP_INSIGHTS_ENABLED } = window['env'];
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: `${window['env'].APPINSIGHTS_INSTRUMENTATIONKEY}`,
    extensions: [reactPlugin, clickPluginInstance],
    loggingLevelTelemetry: 2,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableCorsCorrelation: true,
    enableAjaxPerfTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
    enablePerfMgr: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
    autoTrackPageVisitTime: true,
    distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
    enableAjaxErrorStatusText: true,
  },
});

ai.loadAppInsights();

// eslint-disable-next-line no-native-reassign
const getErrorStack = () => {
  try {
    throw Error('');
  } catch (err) {
    // @ts-ignore
    const fullStack = err.stack;
    const callerLine = fullStack?.split('\n')[3];
    const index = callerLine.indexOf('at ');
    const clean = callerLine.slice(index + 2, callerLine.length);
    return clean;
  }
};
const { warn: origWarn, error: origError } = window.console;
let newConsole = {
  warn: function (message?: any, ...args: any[]) {
    ai.appInsights.trackTrace({ message: `${getErrorStack()}: args[0]`, severityLevel: SeverityLevel.Warning });
    origWarn.apply(window.console, [message, ...args]);
  },
  error: function (message?: any, ...args: any[]) {
    ai.appInsights.trackTrace({ message: `${getErrorStack()}: args[0]`, severityLevel: SeverityLevel.Error });
    origError.apply(window.console, [message, ...args]);
  },
};

window.console = {
  ...window.console,
  warn: newConsole.warn.bind(window.console),
  error: newConsole.error.bind(window.console),
};

export const { appInsights } = ai;

export { reactPlugin };

export const convertArrayToObject = (array: object[]) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      ...item,
    };
  }, initialValue);
};

export const normalizeContent = (envelopeData: { content?: any; contentObj?: any }) => {
  const envelopeRawContent = envelopeData.content;
  if (envelopeRawContent && typeof envelopeRawContent === 'string') {
    const envelopeContent = JSON.parse(envelopeRawContent);
    if (Array.isArray(envelopeContent)) {
      envelopeData.contentObj = convertArrayToObject(envelopeContent);
    } else if (typeof envelopeContent === 'object') {
      envelopeData.contentObj = { ...envelopeContent };
    }
  }
};
export const luiTelemetryInitializer = (envelope: ITelemetryItem): boolean => {
  if (!envelope) {
    return false;
  }
  let accessTokenInstance = AuthService.getInstance().getDefaultAccount();
  // eslint-disable-next-line camelcase
  const idTokenClaims = accessTokenInstance?.idTokenClaims as { sub: string; by_realm: string; email?: string };
  let realm: string = idTokenClaims?.by_realm;
  let email = idTokenClaims?.email;
  envelope.data = envelope.data || {};
  const envelopeData = envelope.data;
  envelope.tags = envelope.tags || [];
  const mfeName = 'Home';
  envelopeData.subNav = aiAppsData?.subnav;
  envelopeData.mfeName = mfeName;
  envelopeData.mfeFullName = `${envelopeData.mfeName}${envelopeData.subNav}`;
  envelopeData.languagePackNamespace = envelopeData.application?.languagePackNamespace;
  if (realm) {
    envelope.tags.by_realm = realm;
    envelopeData.realm = realm;
    const { sessionId } = SessionService.getInstance();
    if (sessionId && idTokenClaims.sub) {
      ai.setAuthenticatedUserContext(
        SessionService.getInstance().sessionId.replace(/[,;=| ]+/g, '_'),
        idTokenClaims.sub.replace(/[,;=| ]+/g, '_')
      );
    }
  }
  const emailItems = email && email.split('@', 2);
  if (emailItems && emailItems.length === 2) {
    [, envelopeData.email_domain] = emailItems;
  }
  if (envelopeData) {
    const application = envelopeData?.application;

    envelopeData.ApplicationPlatform = 'Luminate Portal';
    envelopeData.ApplicationName = '';
    envelopeData.ApplicationName = 'Home';
    envelopeData.mfeName = mfeName;
    envelopeData.frameUrl = '/';
    envelopeData.screen = screen;
    normalizeContent(envelopeData);
    if (application) {
      envelopeData.ApplicationName = application.namespace;
      envelopeData.mfeDisplayName = application.displayName;
      envelopeData.frameUrl = application.frameUrl;
      if (application.languagePackNamespace) {
        envelopeData.languagePackNamespace = application.languagePackNamespace;
      }
      if (envelope.tags) {
        envelope.tags['ai.cloud.role'] = `Luminate Portal - ${application.displayName}`;
        envelope.tags['ai.cloud.roleInstance'] = window.location.origin;
      }
    }
  }
  const emitEvent = LOCAL_APP_INSIGHTS_ENABLED || !isLocalHost();
  return emitEvent;
};

export const setAppInsightsTelemetry = async () => {
  try {
    ai.appInsights.addTelemetryInitializer(luiTelemetryInitializer);
  } catch (error) {
    console.error(error);
  }
};

export function isLocalHost(): boolean {
  try {
    return (
      typeof window !== 'undefined' &&
      /^http:\/\/(localhost|127\.0\.0\.1):\d+\//.test(window.location.href) &&
      !/nolocalhost=1/.test(window.location.href)
    );
  } catch (e) {
    return false;
  }
}

export default function withApplicationInsights<P>(Component: React.ComponentType<P>) {
  return withAITracking(reactPlugin, Component);
}

export { setAiAppsData };
