/*
 * ===============================================================================================================
 *                                Copyright 2023, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import {
  NamespacedNavigationResource,
  NavigationHierarchyLevel,
  NavigationPoint,
  PortalNavigationSelection,
  TaskBundleDisplay,
} from '../../models/portalNavigation.model';
import { PortalNavigationState } from './store/reducer';

export const navResourceToLevel = (
  resource: NamespacedNavigationResource | NavigationPoint | TaskBundleDisplay
): NavigationHierarchyLevel => {
  if (resource.realm === NavigationHierarchyLevel.GLOBAL) {
    return NavigationHierarchyLevel.GLOBAL;
  } else {
    return resource.instance ? NavigationHierarchyLevel.INSTANCE : NavigationHierarchyLevel.REALM;
  }
};

export const taskBundleToNavSelection = (
  state: PortalNavigationState,
  requestedTaskBundle: TaskBundleDisplay
): PortalNavigationSelection => {
  let result: PortalNavigationSelection = {};
  result = state.selectedPortalInstance
    ? {
        ...result,
        instance: { level: NavigationHierarchyLevel.REALM, namespace: state.selectedPortalInstance.namespace },
      }
    : result;
  result = {
    ...result,
    taskBundle: {
      level: navResourceToLevel(requestedTaskBundle),
      namespace: requestedTaskBundle.namespace,
    },
  };
  return result;
};

export const portalNavigationStateToSelection = (
  state: PortalNavigationState,
  requestedPoint: NavigationPoint | undefined
): PortalNavigationSelection => {
  let result: PortalNavigationSelection = {};
  const entryPoint = requestedPoint || state.selectedPortalTaskBundle?.navigationTaskBundle.defaultEntryPointLink;

  result = state.selectedPortalInstance
    ? {
        ...result,
        instance: { level: NavigationHierarchyLevel.REALM, namespace: state.selectedPortalInstance.namespace },
      }
    : result;
  result = state.selectedPortalTaskBundle
    ? {
        ...result,
        taskBundle: {
          level: navResourceToLevel(state.selectedPortalTaskBundle.navigationTaskBundle),
          namespace: state.selectedPortalTaskBundle.navigationTaskBundle.namespace,
        },
      }
    : result;

  result = entryPoint
    ? {
        ...result,
        interface: { level: navResourceToLevel(entryPoint), namespace: entryPoint.namespace },
        subnav: entryPoint.subnav,
        hashLink: entryPoint.hashLink,
      }
    : result;

  return result;
};

export const findNavPointByHashLink = (
  obj: NavigationPoint,
  hashLink: string,
  ancestors: string[] = []
): { navPoint: NavigationPoint; ancestors: string[] } | null => {
  if (obj.hashLink === hashLink) {
    return {
      navPoint: obj,
      ancestors: ancestors,
    };
  }

  if (obj.children && obj.children.length > 0) {
    for (let i = 0; i < obj.children.length; i++) {
      const child = obj.children[i];
      const foundObject = findNavPointByHashLink(child, hashLink, [...ancestors, obj.hashLink]);

      if (foundObject) {
        return foundObject;
      }
    }
  }

  return null;
};

export const findNavPointInArrayByHashLink = (
  arr: NavigationPoint[],
  hashLink: string
): { navPoint: NavigationPoint; ancestors: string[] } | null => {
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    const foundObject = findNavPointByHashLink(obj, hashLink);

    if (foundObject) {
      return foundObject;
    }
  }

  return null;
};
