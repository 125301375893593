/*
 * ===============================================================================================================
 *                                Copyright 2020-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { AccountInfo, PopupRequest, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { AuthError } from '@azure/msal-common';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { AuthenticationResult, ByAccountInfo } from '@jda/lui-portal-utilities';
import stringify from 'json-stringify-safe';
import 'reflect-metadata';
import WaitQueue from 'wait-queue';
import { msalProviderConfig } from './authConfig';
import { SessionService } from './sessionService';
import { LOCAL_STORAGE_LOGIN_ERROR, LOCAL_STORAGE_DOMAIN_HINT_NAME } from '../utils/constants';
import { removeTimeoutOtpFromStorage } from '../utils/appUtils';

class DeferredRequest {
  scopes: string[];
  promise: Promise<AuthenticationResult>;
  resolve: (value: AuthenticationResult) => void;
  reject: (reason?: any) => void;

  constructor() {
    this.promise = new Promise<AuthenticationResult>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

export class AuthService {
  private static instance: AuthService;
  private static sessionService: SessionService;
  private account: AccountInfo | null | undefined;
  private requestQueue = new WaitQueue<DeferredRequest>();
  private pendingRequest: Promise<AuthenticationResult> | null;
  private authProvider: PublicClientApplication;
  private timestamp: Date;
  constructor() {
    this.getAuthProvider();
  }

  static getInstance: () => AuthService = () => {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
      if (!AuthService.sessionService) {
        AuthService.sessionService = SessionService.getInstance();
      }
    }

    return AuthService.instance;
  };

  getUrlDomainHint = () => {
    const urlParams = new URLSearchParams(sanitizeUrl(window.location.search));
    const domainHint = urlParams.get('domain-hint') || urlParams.get('domain_hint');
    return domainHint;
  };

  getDomainHint = (): string => {
    const domainHint = this.getUrlDomainHint() || localStorage.getItem(LOCAL_STORAGE_DOMAIN_HINT_NAME) || '';
    return domainHint;
  };

  setDomainHint = (): void => {
    const domainHint = this.getDomainHint();
    localStorage.setItem(LOCAL_STORAGE_DOMAIN_HINT_NAME, domainHint);
  };

  getAuthToken = async (): Promise<AuthenticationResult> => {
    const request = new DeferredRequest();
    this.requestQueue.push(request);
    await this.processTokenRequest();
    return request.promise;
  };

  logout = async (): Promise<void> => {
    const authToken = await this.getAuthToken();

    await AuthService.sessionService.deleteSession(authToken);
    localStorage.clear();
    sessionStorage.clear();
    this.account = undefined;

    await this.getAuthProvider().logoutRedirect();
  };

  logoutWithError = async (loginCustomError: string): Promise<void> => {
    const authToken = await this.getAuthToken();

    await AuthService.sessionService.deleteSession(authToken);
    this.account = undefined;
    localStorage.clear();
    sessionStorage.clear();
    this.setLoginCustomError(loginCustomError);

    await this.getAuthProvider().logoutRedirect();
  };

  setLoginCustomError = (error: string) => {
    localStorage.setItem(LOCAL_STORAGE_LOGIN_ERROR, error);
  };

  getloginCustomError = (): string | null => {
    const loginCustomError = localStorage.getItem(LOCAL_STORAGE_LOGIN_ERROR);
    return loginCustomError;
  };

  clearloginCustomError = () => {
    localStorage.removeItem(LOCAL_STORAGE_LOGIN_ERROR);
  };

  getDefaultAccount = () => {
    this.account = this.account || this.getResolvedAccount();
    return this.account;
  };

  getAuthUser = (): string => {
    return this.getDefaultAccount()?.name || '';
  };

  getAuthPreferredUsername = (): string => {
    // @ts-ignore
    return this.getDefaultAccount()?.idTokenClaims?.['idp_preferred_username'] || '';
  };

  getLoginRequest = () => {
    const loginHint = this.getAuthPreferredUsername();
    const domainHint = this.getDomainHint();
    const idpHint = this.getIdpHint();

    const loginRequest: PopupRequest = {
      scopes: msalProviderConfig.loginRequestConfig.scopes,
    };
    if (idpHint !== '') {
      loginRequest.extraQueryParameters = { idp_hint: idpHint };
    }
    if (domainHint !== '') {
      loginRequest.domainHint = domainHint;
    }
    if (loginHint !== '') {
      loginRequest.loginHint = this.getAuthPreferredUsername();
    }
    return loginRequest;
  };

  getUrlIdpHint = () => {
    const urlParams = new URLSearchParams(sanitizeUrl(window.location.search));
    const idpHint = urlParams.get('idp-hint') || urlParams.get('idp_hint');
    return idpHint;
  };

  getIdpHint = (): string => {
    const idpHint = this.getUrlIdpHint() || '';
    return idpHint;
  };

  login = async () => {
    const loginRequest = this.getLoginRequest();
    const loginResponse = await this?.authProvider.loginPopup(loginRequest).catch((error) => {
      console.error('Could not log in');
      return Promise.reject(error);
    });

    removeTimeoutOtpFromStorage();

    if (loginResponse) {
      await AuthService.sessionService.createSession(loginResponse as AuthenticationResult);
      this.setDomainHint();
    }
    this.account = loginResponse?.account;
    return loginResponse;
  };

  private processTokenRequest = async (): Promise<AuthenticationResult | undefined> => {
    await this.pendingRequest;
    const request = await this.requestQueue.shift();
    this.pendingRequest = request.promise;
    try {
      const response = await this.requestToken();
      request.resolve(response);
    } catch (error) {
      console.warn(`luminate token: error getting access token: ${stringify(error, null, 2)}`);
      request.reject(error);
    } finally {
      this.pendingRequest = Promise.resolve(this.getEmptyAuthResponse());
    }
    return request?.promise;
  };

  private getResolvedAccount() {
    // need to call getAccount here?
    const currentAccounts = this.getAuthProvider().getAllAccounts();

    if (currentAccounts.length > 1) {
      // Add choose account code here
      console.log('Multiple accounts detected, need to add choose account code.');
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
    return null;
  }

  private requestToken = async (): Promise<AuthenticationResult> => {
    const authProvider = this.getAuthProvider();
    if (!this.getDefaultAccount()) {
      localStorage.clear();
      sessionStorage.clear();
      await authProvider.logoutRedirect();
    }
    const account = this.getDefaultAccount();

    if (account != null) {
      const silentTokenRequestParameters: SilentRequest = {
        scopes: this.getDefaultScopes(),
        forceRefresh: false,
        account,
      };
      const domainHint = this.getDomainHint();
      if (domainHint !== '') {
        silentTokenRequestParameters.extraQueryParameters = {
          domainHint,
        };
      }

      AuthService.sessionService.persistSessionId();

      const response = await authProvider
        .acquireTokenSilent(silentTokenRequestParameters)
        .catch(async (error: AuthError) => {
          console.error(`Error getting token: ${stringify(error)}`);
          localStorage.clear();
          sessionStorage.clear();
          await authProvider.logoutRedirect();
          return Promise.reject(`Unspecified error,  ${error.name}`);
        });

      return response as AuthenticationResult;
    }

    return Promise.reject('Could not get authorization token. Account not found because could not login.');
  };

  sendHeartBeat = async () => {
    try {
      const authToken = await this.getAuthToken();
      await AuthService.sessionService.updateSession(authToken);
      Promise.resolve();
    } catch (err) {
      console.error(`[Auth Service] - sendHeartBeat ${stringify(err)}`);
      Promise.resolve();
    }
  };

  getDefaultScopes = (): Array<string> => {
    return [window['env'].DEFAULT_SCOPE];
  };

  getAuthProvider = () => {
    if (!this.authProvider) {
      this.timestamp = new Date();
      this.authProvider = new PublicClientApplication(msalProviderConfig.msalConfig);
    }

    return this.authProvider;
  };

  getEmptyAuthResponse = (): AuthenticationResult => {
    return {
      accessToken: '',
      account: {} as ByAccountInfo,
      authority: '',
      expiresOn: new Date(),
      fromCache: false,
      idToken: '',
      idTokenClaims: {},
      scopes: [],
      tenantId: '',
      tokenType: '',
      uniqueId: '',
    };
  };
}
