/*
 * ===============================================================================================================
 *                                Copyright 2020-2022, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import React from 'react';
import { Typography, Box, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import backgroundImageWEBP from '../../images/login-background.webp';
import backgroundImagePNG from '../../images/login-background.png';
import copyrightText from '../../constants/copyright';
import { Tokens } from '@jda/lui-common-component-library';

export const LogoutScreen = () => {
  const canUseWebP = React.useMemo(() => {
    const elem = document.createElement('canvas');
    if (elem.getContext && elem.getContext('2d')) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
  }, []);

  return (
    <Box
      py={4}
      width="100%"
      height="100vh"
      style={{
        backgroundColor: '#F1F1F2',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        paddingTop: Tokens.spacing['$spacing-none'],
        backgroundAttachment: 'fixed',
        backgroundPosition: 'right center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage: `url(${canUseWebP ? backgroundImageWEBP : backgroundImagePNG})`,
        zIndex: 9001,
        position: 'absolute',
      }}
    >
      <Box flexDirection="column" flex="1" display="flex" justifyContent="center">
        <Box
          alignItems="center"
          width="100%"
          display="flex"
          justifyContent="center"
          data-testid="logout-in-progress-message"
          flexDirection="column"
          fontWeight="fontWeightLight"
        >
          <Typography
            variant="h2"
            gutterBottom
            style={{ textAlign: 'center', width: '30%', padding: '30px', fontWeight: 'inherit' }}
          >
            Signing Out...
          </Typography>
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      </Box>

      <Box
        data-testid="loogout-footer"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box data-testid="logout-copyright" justifyContent="flex-end" alignItems="flex-end" flexGrow="1" display="flex">
          <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center', paddingBottom: '14px' }}>
            {copyrightText}
          </Typography>
        </Box>
        <Box data-testid="logout-disclaimer" justifyContent="flex-end" alignItems="center" flexGrow="1">
          <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
            The software is the confidential information of Blue Yonder Group, Inc., and is licensed
            <br />
            as restricted rights software. The use, reproduction, or disclosure of this software is
            <br />
            subject to restrictions set forth in your license agreement with Blue Yonder
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LogoutScreen;
