/*
 * ===============================================================================================================
 *                                Copyright 2023, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { Message } from '../../../models/Message';
import GlobalUIComponentsAction, { GlobalUIComponentsActionType } from './actions';
import { Props as ConfirmationDialogProps } from '../../../components/confirmationDialog/ConfirmationDialog';

export type ConfirmationDialogOptions = Pick<
  ConfirmationDialogProps,
  'title' | 'content' | 'confirmButtonText' | 'cancelButtonText'
>;
export type ConfirmationDialogState = ConfirmationDialogOptions & Pick<ConfirmationDialogProps, 'open'>;
export interface GlobalUIComponentsState {
  messageQueue: Message[];
  confirmationDialogState: ConfirmationDialogState;
}

export const initialGlobalUIComponentsState: GlobalUIComponentsState = {
  messageQueue: [],
  confirmationDialogState: { open: false, title: '', content: '', confirmButtonText: '', cancelButtonText: '' },
};

export const globalUIComponentsReducer = (
  state = initialGlobalUIComponentsState, // eslint-disable-line no-eval
  action: GlobalUIComponentsAction
): GlobalUIComponentsState => {
  switch (action.type) {
    case GlobalUIComponentsActionType.PUSH_SNACKBAR_MESSAGE: {
      const messageQueue = Array.from(state.messageQueue);
      messageQueue.push(action.payload);
      return {
        ...state,
        messageQueue,
      };
    }
    case GlobalUIComponentsActionType.POP_SNACKBAR_MESSAGE: {
      return {
        ...state,
        messageQueue: state.messageQueue.slice(1),
      };
    }
    case GlobalUIComponentsActionType.SET_CONFIRMATION_DIALOG: {
      return {
        ...state,
        confirmationDialogState: action.payload,
      };
    }
    default:
      return state;
  }
};

export default globalUIComponentsReducer;
