/*
 * ===============================================================================================================
 *                                Copyright 2020-2023, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

export enum FeatureFlag {
  WITH_DRILLDOWN_MENU = 'WITH_DRILLDOWN_MENU',
  WITH_CUSTOM_LOGO = 'WITH_CUSTOM_LOGO',
  WITH_SETTINGS_CATEGORIES = 'WITH_SETTINGS_CATEGORIES',
  WITH_PORTAL_HIERARCHICAL_NAVIGATION = 'WITH_PORTAL_HIERARCHICAL_NAVIGATION',
  WITH_NOTIFICATION_CENTER = 'WITH_NOTIFICATION_CENTER',
  WITH_GENERIC_LOGIN_ERRORS = 'WITH_GENERIC_LOGIN_ERRORS',
  WITH_DYNAMICS_LANGUAGE_OPTIONS = 'WITH_DYNAMICS_LANGUAGE_OPTIONS',
  WITH_SUCCESS_PORTAL_ONBOARDING = 'WITH_SUCCESS_PORTAL_ONBOARDING',
}

export class EnvironmentFeatureFlags {
  public static getValue<T = boolean>(flag: FeatureFlag, defaultValue?: T): T | undefined {
    const value = window['env'][flag.toString()] as T;
    if (value === undefined) {
      return defaultValue;
    } else {
      return value;
    }
  }
}
