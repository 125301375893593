/*
 * ===============================================================================================================
 *                                Copyright 2023-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { Logo } from '@jda/lui-common-component-library/dist/components/luiDrawer/types';
import { BreadcrumbInfo, Preferences } from '@jda/lui-portal-utilities';
import { Application } from '../../../models/application.model';
import { ApplicationSettingsCategory, SettingsDictionary } from '../../../models/applicationSettings.model';
import { NavigationInterface, NavigationPoint } from '../../../models/portalNavigation.model';
import { BACKDROP_ZINDEX_OFFSET, PORTAL_HELP_ADDRESS_DEFAULT } from '../../../utils/constants';
import AppAction, { AppActionType } from './actions';

export interface AppState {
  theme: string;
  themeSelection: string;
  userPreferences: Preferences | undefined;
  logo: Logo | undefined;
  navPoints: NavigationPoint[];
  navInterfaces: NavigationInterface[];
  useHierarchicalNavigation: boolean;
  currentApplication: Application | NavigationPoint | undefined;
  systemApplications: Application[] | NavigationPoint[];
  applicationList: Application[];
  isInitialized: boolean;
  appSettingsCategories: ApplicationSettingsCategory[];
  breadcrumbs: BreadcrumbInfo[];
  wakelockEnabled: boolean;
  showBackdrop: boolean;
  backdropIndex: number;
  settingsData: SettingsDictionary | undefined;
  fromMenuClick: boolean;
  isSourceCacheRefresh: boolean;
  portalTitle: string | null;
  helpAddress: string;
}

export const initialAppState: AppState = {
  theme: 'light',
  themeSelection: 'ultraLight',
  userPreferences: undefined,
  logo: undefined,
  navPoints: [],
  navInterfaces: [],
  useHierarchicalNavigation: false,
  currentApplication: undefined,
  systemApplications: [],
  applicationList: [],
  isInitialized: false,
  appSettingsCategories: [],
  breadcrumbs: [],
  wakelockEnabled: false,
  showBackdrop: false,
  backdropIndex: BACKDROP_ZINDEX_OFFSET,
  settingsData: undefined,
  fromMenuClick: false,
  isSourceCacheRefresh: false,
  portalTitle: null,
  helpAddress: PORTAL_HELP_ADDRESS_DEFAULT,
};

const appReducer = (state = initialAppState, action: AppAction): AppState => {
  switch (action.type) {
    case AppActionType.INITIALIZE:
      return {
        ...state,
        theme: action.payload.theme,
        themeSelection: action.payload.themeSelection,
        userPreferences: action.payload.userPreferences,
        logo: action.payload.logo,
        navPoints: action.payload.navPoints,
        navInterfaces: action.payload.navInterfaces,
        useHierarchicalNavigation: action.payload.useHierarchicalNavigation,
        currentApplication: action.payload.currentApplication,
        systemApplications: action.payload.systemApplications,
        applicationList: action.payload.applicationList,
        isInitialized: action.payload.isInitialized,
        isSourceCacheRefresh: action.payload.isSourceCacheRefresh,
      };
    case AppActionType.UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case AppActionType.UPDATE_CURRENT_APPLICATION:
      return {
        ...state,
        fromMenuClick: action.payload.fromMenuClick,
        breadcrumbs: action.payload.breadcrumbs || state.breadcrumbs,
        currentApplication: action.payload.currentApplication,
        portalTitle: action.payload.portalTitle !== undefined ? action.payload.portalTitle : state.portalTitle,
        helpAddress: action.payload.helpAddress !== undefined ? action.payload.helpAddress : state.helpAddress,
      };
    case AppActionType.SET_USER_PREFERENCES:
      return {
        ...state,
        userPreferences: action.payload,
      };
    case AppActionType.SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case AppActionType.SET_THEME_SELECTION:
      return {
        ...state,
        themeSelection: action.payload,
      };
    case AppActionType.SET_BREADCRUMBS:
      const jsonData = JSON.stringify(action.payload);
      const jsonBreadcrumbs = JSON.stringify(state.breadcrumbs);
      return jsonData !== jsonBreadcrumbs
        ? {
            ...state,
            breadcrumbs: action.payload,
          }
        : state;
    case AppActionType.SET_WAKELOCK:
      return {
        ...state,
        wakelockEnabled: action.payload,
      };
    case AppActionType.SET_BACKDROP:
      return {
        ...state,
        showBackdrop: action.payload.showBackdrop,
        backdropIndex: action.payload.backdropIndex,
      };
    case AppActionType.SET_SETTINGS_DATA:
      return {
        ...state,
        settingsData: action.payload,
      };
    case AppActionType.SET_NOTIFICATION_SOURCE_CACHE_REFRESH:
      return {
        ...state,
        isSourceCacheRefresh: action.payload,
      };
    case AppActionType.SET_PORTAL_TITLE:
      return {
        ...state,
        portalTitle: action.payload,
      };
    case AppActionType.SET_HELP_ADDRESS:
      return {
        ...state,
        helpAddress: action.payload.buttonLinkAddress,
      };
    default:
      return state;
  }
};

export default appReducer;
