import BaseAction from '../../../models/BaseAction';
import { Message } from '../../../models/Message';
import { ConfirmationDialogState } from './reducer';

export enum GlobalUIComponentsActionType {
  PUSH_SNACKBAR_MESSAGE = '[Global UI Components] Push Snackbar Message',
  POP_SNACKBAR_MESSAGE = '[Global UI Components] Pop Snackbar Message',
  SET_CONFIRMATION_DIALOG = '[Global UI Components] Set Confirmation Dialog',
}

type GlobalUIComponentsAction = BaseAction<GlobalUIComponentsActionType>;

export class PushMessage implements GlobalUIComponentsAction {
  readonly type = GlobalUIComponentsActionType.PUSH_SNACKBAR_MESSAGE;
  readonly payload: Message;

  constructor(message: Message) {
    this.payload = message;
  }
}

export class PopMessage implements GlobalUIComponentsAction {
  readonly type = GlobalUIComponentsActionType.POP_SNACKBAR_MESSAGE;
}

export class SetConfirmationDialog implements GlobalUIComponentsAction {
  readonly type = GlobalUIComponentsActionType.SET_CONFIRMATION_DIALOG;
  readonly payload: ConfirmationDialogState;

  constructor(dialogData: ConfirmationDialogState) {
    this.payload = dialogData;
  }
}

type Action = PushMessage | PopMessage | SetConfirmationDialog;

export default Action;
