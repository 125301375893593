/*
 * ===============================================================================================================
 *                                Copyright 2020-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { LightTheme, LuiLogoTagline, Tokens } from '@jda/lui-common-component-library';
import { Box, ThemeProvider, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import copyrightText from '../../constants/copyright';
import backgroundImagePNG from '../../images/login-background.png';
import backgroundImageWEBP from '../../images/login-background.webp';
import LoginForm from './loginForm/loginForm';

export const LandingScreen = () => {
  useEffect(() => {
    let mounted;
    const iframe = document.getElementById('mfe') as HTMLIFrameElement;
    if (iframe) {
      iframe.className = 'mfe';
    }
    return () => {
      mounted = false;
      return undefined;
    };
  }, []);

  const canUseWebP = React.useMemo(() => {
    const elem = document.createElement('canvas');
    if (elem.getContext && elem.getContext('2d')) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
  }, []);

  return (
    <ThemeProvider theme={LightTheme}>
      <Box
        py={4}
        width="100%"
        height="100vh"
        style={{
          backgroundColor: '#F1F1F2',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'top',
          paddingTop: Tokens.spacing['$spacing-none'],
          backgroundAttachment: 'fixed',
          backgroundPosition: 'right center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundImage: `url(${canUseWebP ? backgroundImageWEBP : backgroundImagePNG})`,
        }}
      >
        <Box flexDirection="column" flex="1" display="flex" justifyContent="center">
          <Box
            alignItems="center"
            width="100%"
            display="flex"
            justifyContent="center"
            style={{ paddingBottom: '20px' }}
          >
            <LuiLogoTagline style={{ fontSize: '3.50rem' }} />
          </Box>
          <Box alignSelf="center" flexDirection="column" style={{ display: 'inherit' }}>
            <LoginForm />
          </Box>
        </Box>
        <Box
          data-testid="login-footer"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box
            data-testid="lui-portal-login-copyright"
            justifyContent="flex-end"
            alignItems="flex-end"
            flexGrow="1"
            display="flex"
          >
            <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
              {copyrightText}
            </Typography>
          </Box>
          <Box data-testid="login-disclaimer" justifyContent="flex-end" alignItems="center" flexGrow="1">
            <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center', padding: '20px' }}>
              The software is the confidential information of Blue Yonder Group, Inc., and is licensed
              <br />
              as restricted rights software. The use, reproduction, or disclosure of this software is
              <br />
              subject to restrictions set forth in your license agreement with Blue Yonder
            </Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LandingScreen;
