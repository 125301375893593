/*
 * ===============================================================================================================
 *                                Copyright 2021-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { X } from '@jda/lui-common-icon-library';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useI18nContext } from '../context/i18n';
import { LOCALIZE_TEMPLATE } from '../utils/constants';
import { Tokens } from '@jda/lui-common-component-library';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialog: {
      minWidth: '481px',
    },
    dialogTitle: {
      paddingLeft: '16px',
    },
    dialogContent: {
      borderBottom: 0,
      minWidth: '481px',
      padding: Tokens.spacing['$sp-4'],
    },
    actions: {
      margin: 0,
      padding: Tokens.spacing['$sp-4'],
      justifyContent: 'flex-end',
      '& button': {
        marginLeft: Tokens.spacing['$spacing-x-small'],
      },
    },
  };
});

interface TimeoutModalProps {
  isOpen: boolean;
  timeout: number;
  handleClose: () => void;
  handleLogout: () => void;
}

export const TimeoutModal = (props: TimeoutModalProps) => {
  let interval: number;
  const classes = useStyles();
  const [remainingTime, setRemainingTime] = useState<number>(props.timeout / 1000);
  const { localizer } = useI18nContext().store;

  useEffect(() => {
    if (props.isOpen) {
      setRemainingTime(props.timeout / 1000);
      interval = window.setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [props.isOpen]);

  return (
    <Dialog
      className={classes.dialog}
      open={props.isOpen}
      maxWidth="sm"
      disableBackdropClick
      onClose={props.handleClose}
      data-tlm-name="luminate-portal-timeout-modal"
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h5">{localizer({ ...LOCALIZE_TEMPLATE, key: 'timeout.modal.title' })}</Typography>
          </Box>
          <Box>
            <IconButton size="small" aria-label="close" onClick={props.handleClose}>
              <X />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Typography gutterBottom>
          {localizer({
            ...LOCALIZE_TEMPLATE,
            key: 'timeout.modal.content',
            valueSubstitutions: { remainingTime: remainingTime.toString() },
          })}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          onClick={props.handleLogout}
          data-testid="luminate-logout-modal-logout-button"
        >
          {localizer({ ...LOCALIZE_TEMPLATE, key: 'menu.item.logout' })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={props.handleClose}
          data-testid="luminate-logout-modal-continue-button"
        >
          {localizer({ ...LOCALIZE_TEMPLATE, key: 'timeout.modal.continue' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
