/*
 * ===============================================================================================================
 *                                Copyright 2020-2021, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import AzureADWrapper from './AzureADWrapper';
import { ApplicationEnvironmentProvider } from './context/environment/provider';
import { I18ContextProvider } from './context/i18n/provider';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <ApplicationEnvironmentProvider>
    <I18ContextProvider>
      <AzureADWrapper />
    </I18ContextProvider>
  </ApplicationEnvironmentProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
