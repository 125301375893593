/*
 * ===============================================================================================================
 *                                Copyright 2021-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { LogLevel } from '@azure/msal-browser';

const clientId = `${window['env'].CLIENT_ID}`;
const postLogoutRedirectUri = `${window.location.origin}/`;
const redirectUri = `${window.location.origin}`;
const scopes = (window['env'].DEFAULT_SCOPE || '').split(',');
const authorityMetadata = window['env'].MSAL_AUTHORITY_METADATA;
const msalLogLevel = window['env'].MSAL_LOG_LEVEL as string;

const authority =
  window['env'].AUTHORITY_ADDRESS ||
  `https://${window['env'].B2C_DOMAIN_HOST}/${window['env'].TENANT_HOST}/${window['env'].POLICY_NAME}`;
// @ts-ignore
const loggingLevel = LogLevel[msalLogLevel];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
const getLoggerCallback = () => {
  return (level: LogLevel, message: string, containsPii: boolean) => {
    if (containsPii) {
      return;
    }
    switch (level) {
      case LogLevel.Error:
        console.error(message);
        return;
      case LogLevel.Info:
        console.info(message);
        return;
      case LogLevel.Verbose:
        console.debug(message);
        return;
      case LogLevel.Warning:
        console.warn(message);
        return;
    }
  };
};

const msalProviderConfig = {
  type: 'popup',
  msalConfig: {
    auth: {
      clientId,
      authority,
      authorityMetadata,
      knownAuthorities: [authority],
      redirectUri,
      postLogoutRedirectUri,
      protocol: 'OIDC',
      responseType: 'idToken',
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    system: {
      loggerOptions: {
        loggerCallback: getLoggerCallback(),
        logLevel: loggingLevel,
      },
    },
  },
  silentRequestConfig: {
    scopes: [...scopes, 'offline_access'],
  },
  endSessionRequestConfig: {},
  loginRequestConfig: {
    scopes: [...scopes, 'offline_access'],
  },
};

export { msalProviderConfig };
