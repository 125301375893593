/*
 * ===============================================================================================================
 *                                Copyright 2023-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import TimerAction, { TimerActionType } from './actions';
import { DEFAULT_INACTIVITY_TIMEOUT } from '../../../utils/constants';

export interface TimerState {
  isLogoutTimeoutModalDisplayed: boolean;
  isDoingCleanup: boolean;
  timeoutOptIn: boolean;
  logoutCleanupTimeout: number;
  timeoutWarningTime: number;
  inactivityTimeout: number;
}

export const initialTimerState: TimerState = {
  isLogoutTimeoutModalDisplayed: false,
  isDoingCleanup: false,
  timeoutOptIn: false,
  logoutCleanupTimeout: 0,
  inactivityTimeout: 0,
  timeoutWarningTime: 0,
};

const timerReducer = (state = initialTimerState, action: TimerAction): TimerState => {
  switch (action.type) {
    case TimerActionType.SET_INITIAL_TIMEOUT_VALUES:
      const INACTIVITY_TIMEOUT = (window.env.INACTIVITY_TIMEOUT as number) || DEFAULT_INACTIVITY_TIMEOUT;
      return {
        ...state,
        timeoutWarningTime: action.payload.timeoutWarningTime,
        inactivityTimeout:
          action.payload.inactivityTimeout > 0 ? action.payload.inactivityTimeout : INACTIVITY_TIMEOUT * 1000,
      };
    case TimerActionType.SET_LOGOUT_CLEANUP_TIMEOUT:
      return {
        ...state,
        logoutCleanupTimeout: action.payload,
      };
    case TimerActionType.START_IDLE_LOGOUT:
      return {
        ...state,
        isLogoutTimeoutModalDisplayed: false,
        isDoingCleanup: true,
      };
    case TimerActionType.SET_TIMEOUT_MODAL_DISPLAY:
      return {
        ...state,
        isLogoutTimeoutModalDisplayed: action.payload,
      };
    case TimerActionType.LOGOUT_REJECT:
      return {
        ...state,
        isDoingCleanup: false,
      };
    case TimerActionType.TIMEOUT_OPT_IN:
      return {
        ...state,
        timeoutOptIn: true,
      };
    default:
      return state;
  }
};

export default timerReducer;
