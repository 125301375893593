/*
 * ===============================================================================================================
 *                                Copyright 2023, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { CustomAvatarMenu, HeaderItem } from '@jda/lui-portal-utilities';
import BaseAction from '../../../models/BaseAction';
import { IconDisplayState, NotificationIconDisplayState } from '../provider';

export enum AppBarActionType {
  SET_IS_LOADING = '[AppBar] Set is loading',
  SET_NOTIFICATIONS_MENU = '[AppBar] Set notifications menu',
  SET_COLLABORATION_MENU = '[AppBar] Set collaboration menu',
  SET_AIASSISTANT_MENU = '[AppBar] Set AIAssistant menu',
  SET_HAMBURGER_MENU = '[AppBar] Set hamburger menu',
  SET_CUSTOM_AVATAR_MENU = '[AppBar] Set custom avatar menu',
  SET_CUSTOM_HEADER_ITEMS = '[AppBar] Set custom header items',
  CLEAR_APP_BAR_CUSTOMIZATIONS = '[AppBar] clear customizations',
}

export type AppBarAction = BaseAction<AppBarActionType>;

export class SetIsLoading implements AppBarAction {
  readonly type = AppBarActionType.SET_IS_LOADING;
  readonly payload: boolean;

  constructor(isLoading: boolean) {
    this.payload = isLoading;
  }
}
export class SetNotificationsMenu implements AppBarAction {
  readonly type = AppBarActionType.SET_NOTIFICATIONS_MENU;
  readonly payload: NotificationIconDisplayState;

  constructor(stateUpdate: NotificationIconDisplayState) {
    this.payload = stateUpdate;
  }
}

class BaseSetIconMenu {
  readonly payload: IconDisplayState;

  constructor(stateUpdate: IconDisplayState) {
    this.payload = stateUpdate;
  }
}
export class SetCollaborationMenu extends BaseSetIconMenu implements AppBarAction {
  readonly type = AppBarActionType.SET_COLLABORATION_MENU;
}

export class SetAIAssistantMenu extends BaseSetIconMenu implements AppBarAction {
  readonly type = AppBarActionType.SET_AIASSISTANT_MENU;
}
export class SetHamburgerMenu extends BaseSetIconMenu implements AppBarAction {
  readonly type = AppBarActionType.SET_HAMBURGER_MENU;
}
export class SetCustomAvatarMenu implements AppBarAction {
  readonly type = AppBarActionType.SET_CUSTOM_AVATAR_MENU;
  readonly payload: CustomAvatarMenu;

  constructor(customAvatarMenu: CustomAvatarMenu) {
    this.payload = customAvatarMenu;
  }
}
export class SetCustomHeaderItems implements AppBarAction {
  readonly type = AppBarActionType.SET_CUSTOM_HEADER_ITEMS;
  readonly payload: HeaderItem[];

  constructor(headerItems: HeaderItem[]) {
    this.payload = headerItems;
  }
}

export class ClearAppBarCustomizations implements AppBarAction {
  readonly type = AppBarActionType.CLEAR_APP_BAR_CUSTOMIZATIONS;
}

type Action =
  | SetIsLoading
  | SetNotificationsMenu
  | SetCollaborationMenu
  | SetAIAssistantMenu
  | SetHamburgerMenu
  | SetCustomAvatarMenu
  | SetCustomHeaderItems
  | ClearAppBarCustomizations;

export default Action;
