/*
 * ===============================================================================================================
 *                                Copyright 2023, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { CustomAvatarMenu, HeaderItem } from '@jda/lui-portal-utilities';
import AppBarAction, { AppBarActionType } from './actions';

export interface ApplicationBarState {
  isLoadingApplication: boolean;
  isNotificationsMenuActive: boolean;
  isAIAssistantMenuActive: boolean;
  notificationMessageCount: number;
  hamburgerMenuActive: boolean;
  isCollaborationMenuActive: boolean;
  customAvatarMenu: CustomAvatarMenu;
  customHeaderItems: HeaderItem[];
}

export const uncustomizedApplicationBarState = {
  isNotificationsMenuActive: false,
  isAIAssistantMenuActive: false,
  notificationMessageCount: 0,
  hamburgerMenuActive: false,
  isCollaborationMenuActive: false,
  customAvatarMenu: { isActive: true, items: [] },
  customHeaderItems: [],
};

export const initialApplicationBarState: ApplicationBarState = {
  ...uncustomizedApplicationBarState,
  isLoadingApplication: false,
};

const appBarReducer = (state = initialApplicationBarState, action: AppBarAction): ApplicationBarState => {
  switch (action.type) {
    case AppBarActionType.SET_IS_LOADING:
      return {
        ...state,
        isLoadingApplication: action.payload,
      };
    case AppBarActionType.SET_NOTIFICATIONS_MENU:
      return {
        ...state,
        isNotificationsMenuActive: action.payload.isActive,
        notificationMessageCount: action.payload.count,
      };
    case AppBarActionType.SET_COLLABORATION_MENU:
      return {
        ...state,
        isCollaborationMenuActive: action.payload.isActive,
      };
    case AppBarActionType.SET_AIASSISTANT_MENU:
      return {
        ...state,
        isAIAssistantMenuActive: action.payload.isActive,
      };
    case AppBarActionType.SET_HAMBURGER_MENU:
      return {
        ...state,
        hamburgerMenuActive: action.payload.isActive,
      };
    case AppBarActionType.SET_CUSTOM_AVATAR_MENU:
      return {
        ...state,
        customAvatarMenu: action.payload,
      };
    case AppBarActionType.SET_CUSTOM_HEADER_ITEMS:
      return {
        ...state,
        customHeaderItems: action.payload,
      };
    case AppBarActionType.CLEAR_APP_BAR_CUSTOMIZATIONS:
      return {
        ...state,
        ...uncustomizedApplicationBarState,
      };
    default:
      return state;
  }
};

export default appBarReducer;
