/*
 * ===============================================================================================================
 *                                Copyright 2020-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import React from 'react';
import { ApplicationEnvironment } from '.';
import { csvToArray } from '../../utils/csvToArray';

const ENVIRONMENT_KEY = 'env';

interface Props {
  children: React.ReactNode;
}

const initialEnvironmentState: ApplicationEnvironment = {
  appInsightsInstrumentationKey: '',
  azureAppName: '',
  clientId: '',
  configurationServiceUrl: '',
  defaultScpScope: '',
  docTitle: '',
  headerHeight: '',
  i18nServiceUrl: '',
  iconsUrl: '',
  localAppInsightsEnabled: false,
  localMfe: '',
  menuWidth: '',
  msalLogLevel: '',
  portalB2cDomainHost: '',
  portalMsalPolicyName: '',
  portalMsalTenantHost: '',
  portalRegistrationService: '',
  realmServiceUrl: '',
  baseApimUrl: '',
  sessionServiceUrl: '',
};

export const ApplicationEnvironmentContext = React.createContext(initialEnvironmentState);

export const ApplicationEnvironmentProvider = ({ children }: Props) => {
  const [environment, setEnvironment] = React.useState(initialEnvironmentState);

  const initializeEnvironmentVariables = () => {
    const windowEnvironment = window[ENVIRONMENT_KEY];

    const {
      APPINSIGHTS_INSTRUMENTATIONKEY,
      AZURE_APP_NAME,
      B2C_DOMAIN_HOST,
      CLIENT_ID,
      CONFIGURATION_SERVICE,
      DEFAULT_SCOPE,
      DOC_TITLE,
      HEADER_HEIGHT,
      I18N_SERVICE,
      ICONS_URL,
      LOCAL_APP_INSIGHTS_ENABLED,
      LOCAL_MFE,
      MENU_WIDTH,
      MSAL_LOG_LEVEL,
      POLICY_NAME,
      REALM_SERVICE,
      REGISTRATION_URL,
      TENANT_HOST,
      BASE_APIM_URL,
      SESSION_SERVICE_URL,
    } = windowEnvironment;

    setEnvironment({
      appInsightsInstrumentationKey: APPINSIGHTS_INSTRUMENTATIONKEY,
      azureAppName: AZURE_APP_NAME,
      clientId: CLIENT_ID,
      configurationServiceUrl: CONFIGURATION_SERVICE,
      defaultScpScope: DEFAULT_SCOPE,
      docTitle: DOC_TITLE,
      headerHeight: HEADER_HEIGHT,
      i18nServiceUrl: I18N_SERVICE,
      iconsUrl: ICONS_URL,
      localAppInsightsEnabled: LOCAL_APP_INSIGHTS_ENABLED,
      localMfe: LOCAL_MFE,
      menuWidth: MENU_WIDTH,
      msalLogLevel: MSAL_LOG_LEVEL,
      portalB2cDomainHost: B2C_DOMAIN_HOST,
      portalMsalPolicyName: POLICY_NAME,
      portalMsalTenantHost: TENANT_HOST,
      portalRegistrationService: REGISTRATION_URL,
      realmServiceUrl: REALM_SERVICE,
      baseApimUrl: BASE_APIM_URL,
      sessionServiceUrl: SESSION_SERVICE_URL,
    });
  };

  React.useEffect(initializeEnvironmentVariables, []);

  return (
    <ApplicationEnvironmentContext.Provider value={environment}>{children}</ApplicationEnvironmentContext.Provider>
  );
};

export const useApplicationEnvironment = () => React.useContext(ApplicationEnvironmentContext);
