/*
 * ===============================================================================================================
 *                                Copyright 2021, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

// might need to be able to display an error message without being able to retrieve language packs or preferences
import { I18N_NAMESPACE } from '../../utils/constants';
import { LocalizationValueRequestParameter } from '@jda/lui-portal-utilities';

export const fallBackLocalizer = (localizationRequestParameter: LocalizationValueRequestParameter): string => {
  const { key } = localizationRequestParameter;
  const namespace: string = localizationRequestParameter.namespace || I18N_NAMESPACE;
  let localizedValue: string =
    localizationRequestParameter.valueOverrides?.[namespace]?.[key] ||
    localizationRequestParameter.valueFallbacks?.[namespace]?.[key] ||
    key;
  if (localizationRequestParameter.valueSubstitutions) {
    const substitutionInterpolater = {
      message: '',

      interpolate: function (localizationValueSubstitutions: { [valueKey: string]: string }) {
        const names = Object.keys(localizationValueSubstitutions);
        const vals = Object.values(localizationValueSubstitutions);
        // eslint-disable-next-line no-new-func
        return new Function(...names, `try { return \`${this.message}\`; }catch(error){ return undefined}`)(...vals);
      },
    };

    substitutionInterpolater.message = localizedValue.replace('`', '\\`');

    localizedValue = substitutionInterpolater.interpolate(localizationRequestParameter.valueSubstitutions) ?? key;
  }
  return localizedValue;
};
