/*
 * ===============================================================================================================
 *                                Copyright 2021-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { IFRAME_PORTAL_URL } from './constants';
import {
  NAV_URL_CONSTANTS,
  NavigationHierarchyLevel,
  PortalNavigationSelection,
} from '../models/portalNavigation.model';
import { PortalNavigationState } from '../context/portal-navigation/store/reducer';

/**
 * Replaces multiple slashes with single in a url respecting the protocol and fragment
 * @param {string} url
 * @returns {string}
 */
export const replaceMultipleSlashesWithSingle = (uri: string): string => {
  const uriParts = uri.match(/(https?:\/\/)(.*)/);

  if (uriParts && uriParts.length) {
    const [, protocol, url] = uriParts;

    const [firstPart, ...restParts] = splitOnFirstOccurrence(url, '#');
    const lastPart = restParts.length ? '#' + restParts.join('') : '';

    return protocol + firstPart.replace(/(\/{2,})/g, '/') + lastPart;
  }

  return uri;
};

export const splitOnFirstOccurrence = (value: string, searchString: string): string[] => {
  const index = value.indexOf(searchString);

  if (index > -1) {
    return [value.slice(0, index), value.slice(index + 1)];
  }

  return [value];
};

export const getUrlNamespace = (url: string = '') => {
  let namespace = url;

  if (url.includes('/')) {
    const paths = url.split('/');
    namespace = url.startsWith('/') ? paths[1] : paths[0];
  }

  if (namespace.includes('?')) {
    [namespace] = namespace.split('?');
  }

  return namespace;
};

export const urlToNavigationSelection = (url: string = ''): PortalNavigationSelection => {
  const portalState = getUrlNamespace(url);
  const offset = url.startsWith('/') ? 1 : 0;
  const subnav: string = url.length > portalState.length ? url.slice(offset + portalState.length) : '';
  const split = portalState.split(/(lui_\(..\)|lui_\(\w+\))/g);

  let result: PortalNavigationSelection = subnav ? { subnav } : {};

  if (split.length < 1) {
    return result;
  }

  if (split[0] !== '') {
    return { ...result, interface: { namespace: split[0], level: NavigationHierarchyLevel.GLOBAL } };
  }

  for (let i = 1; i < split.length; i += 2) {
    const prefix = split[i].substring(0, 6);
    const suffix = split[i].substring(6, 7) + ')';

    let level;
    switch (prefix) {
      case NAV_URL_CONSTANTS.REALM_PREFIX:
        level = NavigationHierarchyLevel.REALM;
        break;
      case NAV_URL_CONSTANTS.INSTANCE_PREFIX:
        level = NavigationHierarchyLevel.INSTANCE;
        break;
      default:
        level = NavigationHierarchyLevel.GLOBAL;
    }

    if (split[i + 1] !== '') {
      const value = { level, namespace: split[i + 1] };
      switch (suffix) {
        case NAV_URL_CONSTANTS.INSTANCE_SUFFIX:
          result.instance = value;
          break;
        case NAV_URL_CONSTANTS.TASKBUNDLE_SUFFIX:
          result.taskBundle = value;
          break;
        default:
          result.interface = value;
          result.hashLink = split[i].slice(7, -1);
      }
    }
  }
  return result;
};

const hierarchyLevelToPrefix = (level: NavigationHierarchyLevel) => {
  if (level === NavigationHierarchyLevel.REALM) {
    return NAV_URL_CONSTANTS.REALM_PREFIX;
  } else if (level === NavigationHierarchyLevel.INSTANCE) {
    return NAV_URL_CONSTANTS.INSTANCE_PREFIX;
  } else {
    return NAV_URL_CONSTANTS.GLOBAL_PREFIX;
  }
};

export const navigationSelectionToUrl = (selection: PortalNavigationSelection): string => {
  let output = '/';

  output += selection.interface
    ? `${hierarchyLevelToPrefix(selection.interface.level)}${NAV_URL_CONSTANTS.INTERFACE_SUFFIX.replace(
        ')',
        `${selection.hashLink ?? ''})`
      )}${selection.interface.namespace}`
    : '';
  output += selection.instance
    ? `${hierarchyLevelToPrefix(selection.instance.level)}${NAV_URL_CONSTANTS.INSTANCE_SUFFIX}${
        selection.instance.namespace
      }`
    : '';
  output += selection.taskBundle
    ? `${hierarchyLevelToPrefix(selection.taskBundle.level)}${NAV_URL_CONSTANTS.TASKBUNDLE_SUFFIX}${
        selection.taskBundle.namespace
      }`
    : '';

  output += selection.subnav && !selection.subnav.startsWith('/') ? '/' : '';
  output += selection.subnav ? selection.subnav : '';

  return output;
};

export const urlJoinWithPortalHost = (
  base: string | undefined,
  path: string,
  isFramePortalUrlExcluded: boolean | undefined
): string => {
  const urlBase = base || '';
  const url = replaceMultipleSlashesWithSingle(urlBase + path);

  let urlWithPortalHost = new URL(url);

  if (isFramePortalUrlExcluded !== true) {
    urlWithPortalHost.searchParams.set(IFRAME_PORTAL_URL, window.location.origin);
  }

  const [firstPart, ...restParts] = splitOnFirstOccurrence(urlWithPortalHost.toString(), '?');
  const lastPart = restParts.length ? '?' + restParts.join('') : '';

  // remove trailing slash
  if (isFramePortalUrlExcluded !== true) {
    return firstPart.replace(/\/$/, '') + lastPart;
  } else {
    return `${firstPart}${lastPart}`;
  }
};

const urlJoin = (path: string, base: string = ''): string => {
  const url = replaceMultipleSlashesWithSingle(base + path);

  return url;
};

export default urlJoin;
