/*
 * ===============================================================================================================
 *                                Copyright 2022-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { AuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { LocalizationFallbacks } from '@jda/lui-portal-utilities';
import { Box, Button, CircularProgress, Link, Typography } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import React, { useState } from 'react';
import { AuthService } from '../../../auth/authService';
import { useI18nContext } from '../../../context/i18n';
import { I18N_NAMESPACE, LOCAL_STORAGE_LOGIN_ERROR } from '../../../utils/constants';
import { EnvironmentFeatureFlags, FeatureFlag } from '../../../utils/environmentFeatureFlags';

// might not be able to retrieve language packs if getting landingScreen, so fallback messages are needed
const localizationFallbackTitle = {
  'error.fallback.title.generic_error': 'Login failed',
  'error.fallback.title.generic_error.user_cancelled': 'Login cancelled by user',
};
const localizationFallbackMessages = {
  'error.fallback.generic_error': 'An internal error has occurred. Please try again later.',
  'error.fallback.generic_error.user_cancelled': 'Log in again to continue.',
  'error.fallback.generic_error.user_error': 'Try to log again or contact your administrator.',
  'error.fallback.endpoints_resolution_error':
    'You have experienced an error: ${errorCode}, please wait between 30-60 minutes and try again',
  'error.fallback.popup_window_error':
    'You have experienced an error: ${errorCode}, please enable popup windows on your browser and try again',
  'error.fallback.token_renewal_error':
    'You have experienced an error: ${errorCode}, click on the sign in button to try again',
  'error.fallback.interaction_required':
    'You have experienced an error: ${errorCode}, please provide extra data required',
  'error.fallback': 'You have experienced an error: ${errorCode}',
  'error.fallback.user_cancelled':
    'You have experienced an error: ${errorCode}, click on the sign in button to try again',
  'error.fallback.not_authorized': 'Please ask your administrator for permissions',
  'error.fallback.server_error': 'An internal error has occurred. Please contact your system administrator',
};

const localizationFallback: LocalizationFallbacks = {
  [I18N_NAMESPACE]: {
    ...localizationFallbackMessages,
    ...localizationFallbackTitle,
  },
};
const loginCustomError = localStorage.getItem(LOCAL_STORAGE_LOGIN_ERROR) || undefined;

const LoginForm: React.FC = () => {
  const { inProgress } = useMsal();
  const [error, setError] = useState(new AuthError(loginCustomError));
  const { localizer } = useI18nContext().store;

  const handleLogin = React.useCallback(async () => {
    try {
      AuthService.getInstance().clearloginCustomError();
      await AuthService.getInstance().login();
      setError(new AuthError());
    } catch (error) {
      console.error('Got error back');
      console.error(error);
      setError(error as AuthError);
    }
  }, []);

  const getErrorTitle = React.useCallback(
    (errorCode: string): string => {
      switch (errorCode) {
        case 'user_cancelled':
          return localizer({
            key: `error.fallback.title.generic_error.${errorCode}`,
            valueFallbacks: localizationFallback,
            valueSubstitutions: { errorCode },
          });
        case 'popup_window_error':
        case 'not_authorized':
        case 'interaction_required':
        case 'login_required':
        case 'consent_required':
        case 'server_error':
        case 'token_renewal_error':
        case 'endpoints_resolution_error':
        default:
          return localizer({
            key: 'error.fallback.title.generic_error',
            valueFallbacks: localizationFallback,
          });
      }
    },
    [localizer]
  );

  const getErrorMessage = React.useCallback(
    (errorCode: string): string => {
      switch (errorCode) {
        case 'user_cancelled':
          return localizer({
            key: `error.fallback.generic_error.${errorCode}`,
            valueFallbacks: localizationFallback,
            valueSubstitutions: { errorCode },
          });
        case 'popup_window_error':
        case 'not_authorized':
        case 'interaction_required':
          return localizer({
            key: `error.fallback.generic_error.user_error`,
            valueFallbacks: localizationFallback,
          });
        case 'endpoints_resolution_error':
        case 'token_renewal_error':
        case 'server_error':
        case 'login_required':
        case 'consent_required':
        default:
          return localizer({
            key: 'error.fallback.generic_error',
            valueFallbacks: localizationFallback,
            valueSubstitutions: { errorCode },
          });
      }
    },
    [localizer]
  );

  const getErrorMessageLogs = React.useCallback(
    (errorCode: string): string => {
      switch (errorCode) {
        case 'endpoints_resolution_error':
        case 'popup_window_error':
        case 'user_cancelled':
        case 'token_renewal_error':
        case 'not_authorized':
        case 'server_error':
        case 'interaction_required':
          return localizer({
            key: `error.fallback.${errorCode}`,
            valueFallbacks: localizationFallback,
            valueSubstitutions: { errorCode },
          });
        case 'login_required':
        case 'consent_required':
        default:
          return localizer({
            key: 'error.fallback',
            valueFallbacks: localizationFallback,
            valueSubstitutions: { errorCode },
          });
      }
    },
    [localizer]
  );

  if (inProgress === InteractionStatus.Login) {
    return (
      <Box data-testid="login-in-progress-message" display="flex" alignItems="center" flexDirection="column">
        <Box p={4} fontWeight="fontWeightLight">
          <Typography variant="h2" style={{ fontWeight: 'inherit' }} gutterBottom>
            We are signing you in...
          </Typography>
        </Box>
        <Box>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  const hasError = error.errorMessage || error.errorCode;
  if (hasError) {
    console.error(
      `\nPortal Login Error Code: `,
      error.errorCode,
      `\nPortal Login Error Message: `,
      error.errorMessage || getErrorMessageLogs(error.errorCode)
    );
  }
  const withGenericLoginError =
    EnvironmentFeatureFlags.getValue<string>(FeatureFlag.WITH_GENERIC_LOGIN_ERRORS) === 'true';

  return (
    <>
      {hasError && !withGenericLoginError && (
        <>
          <Box data-testid="login-error-code" alignSelf="center">
            <Typography variant="h2">
              <Box fontWeight="fontWeightBold">{error.errorCode}</Box>
            </Typography>
          </Box>
          <Box data-testid="login-error-message" paddingTop={1.5} paddingBottom={4} alignSelf="center">
            <Typography variant="body1">{error.errorMessage || getErrorMessageLogs(error.errorCode)}</Typography>
          </Box>
        </>
      )}
      {hasError && withGenericLoginError && (
        <>
          <Box data-testid="login-error-code" alignSelf="center">
            <Typography variant="h2">
              <Box fontWeight="fontWeightBold">{getErrorTitle(error.errorCode)}</Box>
            </Typography>
          </Box>
          <Box data-testid="login-error-message" paddingTop={1.5} paddingBottom={4} alignSelf="center">
            <Typography variant="body1">{getErrorMessage(error.errorCode)}</Typography>
          </Box>
        </>
      )}
      <Box alignSelf="center">
        <Button
          id="login-button"
          data-testid="login-button"
          variant="contained"
          color="primary"
          onClick={handleLogin}
          startIcon={<LockOpenIcon />}
        >
          Sign in
        </Button>
      </Box>
    </>
  );
};

export default LoginForm;
