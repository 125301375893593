/*
 * ===============================================================================================================
 *                                Copyright 2021-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

export const CLEANUP_MFE_COUNT_KEY = 'cleanupMfeCount';
export const ACKNOWLEDGED_MFE_COUNT_KEY = 'acknowledgedMfeCount';
export const PORTAL_LOGOUT_KEY = 'portalLogout';
export const LOGOUT_CANCEL_KEY = 'logoutCancel';
export const LOGOUT_REJECT_KEY = 'logoutReject';
export const LOGOUT_CLEANUP_KEY = 'cleanupTimeout';
export const TIMEOUT_OPT_IN_KEY = 'timeoutOptIn';
export const VERIFY_PORTAL_ACTIVITY = 'verifyPortalActivity;';
