/*
 * ===============================================================================================================
 *                                Copyright 2020-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { MsalProvider, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Palette, Tokens } from '@jda/lui-common-component-library';
import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { AuthService } from './auth/authService';
import LandingScreen from './components/landingScreen/landingScreen';
import AppProvider from './context/app/provider';
import ApplicationBarProvider from './context/application-bar/provider';
import ApplicationDrawerProvider from './context/application-drawer/provider';
import PortalNavigationProvider from './context/portal-navigation/provider';
import TimersProvider from './context/timers/provider';
import { ByAccountInfo, FeatureFlagsProvider, getFeatureFlag } from '@jda/lui-portal-utilities';
import ThemeWrapper from './ThemeWrapper';
import { PORTAL_FRAME_TITLE, PORTAL_FRAME_TITLE_LEGACY, WITH_TITLE_UPDATE } from './utils/constants';
import { updatePortalTitle } from './utils/appUtils';
const App = React.lazy(() => import('./app'));

export const LoadingOverlay = () => (
  <Backdrop transitionDuration={0} open style={{ backgroundColor: Palette.colors.common.white, zIndex: 9000 }}>
    <CircularProgress
      data-testid="lui-portal-circular-progess-loader"
      variant="indeterminate"
      size="3rem"
      style={{ color: Tokens.colors.lightTheme.primaryMain }}
    />
  </Backdrop>
);
const iframeRef: HTMLIFrameElement = document.getElementById('mfe') as HTMLIFrameElement;

export const MainApp = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();

  const [handlingLogin, setHandlingLogin] = useState(false);
  const [showed, setShowed] = useState(false);

  const isTitleUpdateEnabled = getFeatureFlag(WITH_TITLE_UPDATE);

  useEffect(() => {
    updatePortalTitle({ frameTitle: isTitleUpdateEnabled ? PORTAL_FRAME_TITLE : PORTAL_FRAME_TITLE_LEGACY });
  }, [isTitleUpdateEnabled]);

  const handleLogin = async () => {
    try {
      setHandlingLogin(false);
      await AuthService.getInstance().login();
    } catch (error) {
      console.error('Got error back');
      console.error(error);
    }
  };

  useEffect(() => {
    let urlIdpHint = AuthService.getInstance().getUrlIdpHint()?.trim();

    if (urlIdpHint && urlIdpHint !== '') {
      const urlDomainHint = AuthService.getInstance().getUrlDomainHint()?.trim();
      const initialHandlingLogin = Boolean(
        (urlDomainHint && urlDomainHint !== '') || (urlIdpHint && urlIdpHint !== '')
      );
      setHandlingLogin(initialHandlingLogin);
    } else {
      const urlDomainHint = AuthService.getInstance().getUrlDomainHint()?.trim();
      const initialHandlingLogin = Boolean(urlDomainHint && urlDomainHint !== '');
      setHandlingLogin(initialHandlingLogin);
    }
  }, []);

  useEffect(() => {
    if (!showed && handlingLogin && inProgress === InteractionStatus.None && !isAuthenticated) {
      setShowed(true);
      handleLogin();
    }
  }, [inProgress, handlingLogin]);

  if (isAuthenticated && accounts.length >= 1) {
    const accountInfo = AuthService.getInstance().getDefaultAccount();
    return (
      <React.Suspense fallback={<LoadingOverlay />}>
        <ApplicationBarProvider>
          <ApplicationDrawerProvider>
            <AppProvider iframeRef={iframeRef}>
              <ThemeWrapper>
                <TimersProvider>
                  <PortalNavigationProvider>
                    <App accountInfo={accountInfo} />
                  </PortalNavigationProvider>
                </TimersProvider>
              </ThemeWrapper>
            </AppProvider>
          </ApplicationDrawerProvider>
        </ApplicationBarProvider>
      </React.Suspense>
    );
  } else if (inProgress !== InteractionStatus.HandleRedirect && inProgress !== InteractionStatus.Startup) {
    return <LandingScreen />;
  } else {
    return null;
  }
};

const Harness = () => {
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null | undefined>(undefined);
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  useEffect(() => {
    if (isAuthenticated && accounts.length >= 1) {
      setAccountInfo(AuthService.getInstance().getDefaultAccount());
    }
  }, [accounts, isAuthenticated]);

  return (
    <FeatureFlagsProvider
      apiKey={window.env.LUI_HARNESS_MFE_KEY}
      authInfo={
        accountInfo ||
        ({
          environment: '',
          homeAccountId: '',
          localAccountId: '',
          tenantId: '',
          username: '',
          idTokenClaims: { sub: 'PORTAL_LOGIN' },
        } as unknown as ByAccountInfo)
      }
    >
      <MainApp />
    </FeatureFlagsProvider>
  );
};

function AzureADWrapper() {
  const authProvider = AuthService.getInstance().getAuthProvider();

  return (
    <MsalProvider instance={authProvider}>
      <Harness />
    </MsalProvider>
  );
}

export default AzureADWrapper;
