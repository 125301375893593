/*
 * ===============================================================================================================
 *                                Copyright 2020, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { createContext, useContext } from 'react';

export interface BreadcrumbInfo {
  pathname: string;
  title: string;
}

/** Context information */
export interface BreadcrumbsContext {
  breadcrumbs: BreadcrumbInfo[];
}

/** Default context */
export const BREADCRUMBS_CONTEXT: BreadcrumbsContext = {
  breadcrumbs: [],
};

/** Context implementation */
// @@todo tech debt where code does not conform to eslint pre-commit. context exists in dashboard and utils
// eslint-disable-next-line no-redeclare
export const BreadcrumbsContext = createContext(BREADCRUMBS_CONTEXT);

// @@todo tech debt where code does not conform to eslint pre-commit. Breadcrumb context exists in dashboard and utils
/** Custom context hook */
// eslint-disable-next-line no-redeclare
export const useBreadcrumbsContext = () => useContext(BreadcrumbsContext);

Object.assign(BreadcrumbsContext, { displayName: 'Breadcrumbs' });
