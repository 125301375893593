/*
 * ===============================================================================================================
 *                                Copyright 2023-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import {
  fetchPreferences,
  getConfigurationHelper,
  getEffectivePreferences,
  postPreferences,
  Preferences,
  saveConfigurationHelper,
} from '@jda/lui-portal-utilities';
import { AxiosResponse } from 'axios';
import { AuthService } from '../auth/authService';
import { UserPreferenceData, UserPreferenceDataByApplication } from '../models/userPreferenceData.model';
import { getLogos } from '../service/logoService';
import {
  DEFAULT_THEME,
  LOCAL_STORAGE_LAST_CONNECTION,
  LOCAL_STORAGE_TIMEOUT_OPT_IN,
  PREFERENCES_APP,
  THEMES,
  THEME_STORAGE_KEY,
  THEME_SELECTION_STORAGE_KEY,
  COOKIES_PREFERENCES_OPTIONS,
} from './constants';
import { portalDebug } from './loggingFlags';
let { CONFIGURATION_SERVICE } = window.env;

export const getLandingPage = (preferences: Preferences) => {
  const effectivePreferences = preferences ? getEffectivePreferences(preferences) : {};
  const preferenceLadingPage: string = effectivePreferences['plat.lui.portal']?.landingPage;
  return preferenceLadingPage;
};

export const setThemeStorage = async (shade: string) => {
  await saveConfigurationHelper(
    { url: `${CONFIGURATION_SERVICE}/configuration`, tokenProvider: AuthService.getInstance().getAuthToken },
    'portal',
    THEME_STORAGE_KEY,
    { theme: shade },
    'USER'
  );
};

export const setThemeSelectionStorage = async (themeSelection: string) => {
  await saveConfigurationHelper(
    { url: `${CONFIGURATION_SERVICE}/configuration`, tokenProvider: AuthService.getInstance().getAuthToken },
    'portal',
    THEME_SELECTION_STORAGE_KEY,
    { themeSelection: themeSelection },
    'USER'
  );
};

export const fetchThemeStorage = async (): Promise<AxiosResponse<string>> => {
  try {
    const response = await getConfigurationHelper(
      { url: `${CONFIGURATION_SERVICE}/user/configurations`, tokenProvider: AuthService.getInstance().getAuthToken },
      'portal',
      [THEME_STORAGE_KEY]
    );
    let theme: AxiosResponse<string> | string;
    if ((response as any).data[0]?.configuration.theme in THEMES) {
      theme = (response as any).data[0]?.configuration.theme;
    } else {
      theme = DEFAULT_THEME;
    }
    return theme as any;
  } catch (error) {
    console.error(`Cannot fetch theme from storage: ${JSON.stringify(error)}`);
    return DEFAULT_THEME as any;
  }
};

export const fetchUserPreferences = async () => {
  try {
    const response = await fetchPreferences(
      { url: `${CONFIGURATION_SERVICE}/preferences`, tokenProvider: AuthService.getInstance().getAuthToken },
      PREFERENCES_APP
    );
    return response as any;
  } catch (error) {
    console.error(error);
    return {
      data: '',
      status: 0,
      statusText: '',
      headers: [],
      config: {},
    };
  }
};

export const saveUserPreferences = async (
  userPreferencesByApp: UserPreferenceData,
  app: string = PREFERENCES_APP
): Promise<UserPreferenceDataByApplication> => {
  const response = await postPreferences(
    { url: `${CONFIGURATION_SERVICE}/userPreferences`, tokenProvider: AuthService.getInstance().getAuthToken },
    userPreferencesByApp,
    app
  );
  return response as UserPreferenceDataByApplication;
};

export const fetchLogos = async (): Promise<any> => {
  try {
    const response = await getLogos();
    return response as any;
  } catch (error) {
    console.error(`Cannot fetch logos from storage: ${JSON.stringify(error)}`);
    return Promise.resolve({});
  }
};

export const removeTimeoutOtpFromStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_TIMEOUT_OPT_IN);
  localStorage.removeItem(LOCAL_STORAGE_LAST_CONNECTION);
};

export class WakeLockHandler {
  private static wakeLockRequest: any = null;
  async requestWakeLock() {
    const anyNav: any = navigator;
    if ('wakeLock' in navigator) {
      try {
        WakeLockHandler.wakeLockRequest = await anyNav.wakeLock.request('screen');
      } catch (err: any) {
        portalDebug(`Screen Wake Lock Error: ${err.name}, ${err.message}`);
      }
    }
  }
  async releaseWakeLock() {
    if (WakeLockHandler.wakeLockRequest !== null) {
      WakeLockHandler.wakeLockRequest.release();
      WakeLockHandler.wakeLockRequest = null;
    }
  }
}

interface TitleInput {
  frameTitle: string;
  appTitle?: string;
}

export const updatePortalTitle = (title: TitleInput): void => {
  const titleStr = title.appTitle ? `${title.appTitle} - ${title.frameTitle}` : title.frameTitle;
  document.title = titleStr;
};

export const validateOptInCookies = (preferences: Preferences, isFeatureFlagEnabled: boolean): boolean => {
  const effectivePreferences = preferences ? getEffectivePreferences(preferences) : {};

  const shouldTrackCookies: boolean =
    isFeatureFlagEnabled ||
    effectivePreferences?.[PREFERENCES_APP]?.cookiesPreference === COOKIES_PREFERENCES_OPTIONS.ALLOW_ALL;

  return shouldTrackCookies;
};
