/*
 * ===============================================================================================================
 *                                Copyright 2023, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import BaseAction from '../../../models/BaseAction';
import { InitialTimeoutValuesPayload } from '../../../models/Timer';

export enum TimerActionType {
  SET_INITIAL_TIMEOUT_VALUES = '[Timer] Set initial timeout values',
  SET_LOGOUT_CLEANUP_TIMEOUT = '[Timer] Set logout cleanup timeout',
  START_IDLE_LOGOUT = '[Timer] Start idle logout',
  SET_TIMEOUT_MODAL_DISPLAY = '[Timer] Set timeout modal display',
  LOGOUT_REJECT = '[Timer] Logout reject',
  TIMEOUT_OPT_IN = '[Timer] Timeout opt in',
}

export type TimerAction = BaseAction<TimerActionType>;

export class SetInitialTimeoutValues implements TimerAction {
  readonly type = TimerActionType.SET_INITIAL_TIMEOUT_VALUES;
  readonly payload: InitialTimeoutValuesPayload;

  constructor(data: InitialTimeoutValuesPayload) {
    this.payload = data;
  }
}

export class SetLogoutCleanupTimeout implements TimerAction {
  readonly type = TimerActionType.SET_LOGOUT_CLEANUP_TIMEOUT;
  readonly payload: number;

  constructor(timeout: number) {
    this.payload = timeout;
  }
}

export class StartIdleLogout implements TimerAction {
  readonly type = TimerActionType.START_IDLE_LOGOUT;
}

export class SetTimeoutModalDisplay implements TimerAction {
  readonly type = TimerActionType.SET_TIMEOUT_MODAL_DISPLAY;
  readonly payload: boolean;

  constructor(isDisplayed: boolean) {
    this.payload = isDisplayed;
  }
}

export class LogoutReject implements TimerAction {
  readonly type = TimerActionType.LOGOUT_REJECT;
}

export class TimeoutOptIn implements TimerAction {
  readonly type = TimerActionType.TIMEOUT_OPT_IN;
}

type Action =
  | SetInitialTimeoutValues
  | SetLogoutCleanupTimeout
  | StartIdleLogout
  | SetTimeoutModalDisplay
  | LogoutReject
  | TimeoutOptIn;

export default Action;
