/*
 * ===============================================================================================================
 *                                Copyright 2023-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import BaseAction from '../../../models/BaseAction';
import {
  NavigationHierarchyLevel,
  NavigationInterface,
  NavigationPoint,
  PortalNavigationInstance,
  PortalNavigationSelection,
  TaskBundleDetail,
  TaskBundleDisplay,
} from '../../../models/portalNavigation.model';
import React from 'react';
import registrationService from '../../../service/registrationService';
import { urlToNavigationSelection } from '../../../utils/urlJoin';
import { findNavPointInArrayByHashLink, navResourceToLevel } from '../utils';

export enum PortalNavigationActionType {
  SET_INSTANCES = '[PortalNavigation] Set user instances',
  SET_SELECTED_INSTANCE = '[PortalNavigation] Set user selected instance',
  SET_ACTIVE_MENU_INSTANCE = '[PortalNavigation] Set active menu instance',
  SET_TASK_BUNDLES = '[PortalNavigation] Set task bundles',
  SET_INITIALIZED = '[PortalNavigation] Set Initialized',
}

export interface PortalNavigationInitializationPayload {
  portalInstances: PortalNavigationInstance[] | undefined;
  selectedPortalInstance: PortalNavigationInstance | undefined;
  selectedPortalTaskBundle: TaskBundleDetail | undefined;
  effectiveInterfaces: NavigationInterface[];
  requestedPoint: NavigationPoint | undefined;
  navPointAncestors: string[] | undefined;
}

export type PortalNavigationAction = BaseAction<PortalNavigationActionType>;

export class SetUserInstances implements PortalNavigationAction {
  readonly type = PortalNavigationActionType.SET_INSTANCES;
  readonly payload: PortalNavigationInstance[];

  constructor(instances: PortalNavigationInstance[]) {
    this.payload = instances;
  }
}

export class SetUserSelectedInstance implements PortalNavigationAction {
  readonly type = PortalNavigationActionType.SET_SELECTED_INSTANCE;
  readonly payload: PortalNavigationInstance | undefined;

  constructor(instance: PortalNavigationInstance | undefined) {
    this.payload = instance;
  }
}

export class SetActiveMenuInstance implements PortalNavigationAction {
  readonly type = PortalNavigationActionType.SET_ACTIVE_MENU_INSTANCE;
  readonly payload: PortalNavigationInstance | undefined;

  constructor(instance: PortalNavigationInstance | undefined) {
    this.payload = instance;
  }
}

export class SetTaskBundles implements PortalNavigationAction {
  readonly type = PortalNavigationActionType.SET_TASK_BUNDLES;
  readonly payload: TaskBundleDisplay[];

  constructor(taskBundles: TaskBundleDisplay[]) {
    this.payload = taskBundles;
  }
}

export class SetInitialized implements PortalNavigationAction {
  readonly type = PortalNavigationActionType.SET_INITIALIZED;

  readonly payload: PortalNavigationInitializationPayload;

  constructor(initializationData: PortalNavigationInitializationPayload) {
    this.payload = initializationData;
  }
}

type Action = SetUserInstances | SetUserSelectedInstance | SetActiveMenuInstance | SetTaskBundles | SetInitialized;

export default Action;

const getInitialStateFromSelection = async (selection: PortalNavigationSelection) => {
  const portalInstances = await registrationService.getEnabledPortalInstancesList();
  const instanceNamespace = selection.instance?.namespace;
  const taskBundleNamespace = selection.taskBundle?.namespace;
  const selectedPortalInstance = instanceNamespace
    ? portalInstances.find((instance) => instance.namespace === instanceNamespace)
    : portalInstances[0];
  let selectedPortalTaskBundle = taskBundleNamespace
    ? await registrationService.getTaskBundle(taskBundleNamespace, instanceNamespace)
    : undefined;

  const effectiveInterfaces = selectedPortalTaskBundle?.interfaces
    ? selectedPortalTaskBundle?.interfaces
    : (await registrationService.getEffectiveInterfaces(taskBundleNamespace))?.interfaces;

  let requestedPoint: NavigationPoint | undefined;
  let navPointAncestors: string[] | undefined;

  if (selectedPortalTaskBundle?.navigationTaskBundle === undefined) {
    selectedPortalTaskBundle = undefined;
  }

  if (selection.interface && effectiveInterfaces) {
    let requestedNavPoint;

    if (selectedPortalTaskBundle?.navigationTaskBundle?.navigation[0].children) {
      if (selection.hashLink) {
        const result = findNavPointInArrayByHashLink(
          selectedPortalTaskBundle?.navigationTaskBundle?.navigation[0].children,
          selection.hashLink
        );
        requestedNavPoint = result?.navPoint;
        navPointAncestors = result?.ancestors;
      } else {
        requestedNavPoint = selectedPortalTaskBundle?.navigationTaskBundle?.navigation[0].children.find(
          (navPoint) => navPoint.namespace === selection.interface?.namespace
        );
      }
    }

    if (requestedNavPoint) {
      requestedPoint = { ...requestedNavPoint, subnav: selection.subnav };
    } else {
      const requestedInterface = effectiveInterfaces.find(
        (interfaceMember) => interfaceMember.namespace === selection.interface?.namespace
      ) as any;
      if (requestedInterface) {
        requestedPoint = { ...requestedInterface, subnav: selection.subnav } as NavigationPoint;
      }
    }
  }

  return {
    portalInstances,
    selectedPortalInstance,
    selectedPortalTaskBundle,
    effectiveInterfaces,
    requestedPoint,
    navPointAncestors,
  };
};

export const initializePortalNavigationFromAddress = async (dispatch: React.Dispatch<SetInitialized>, path: string) => {
  let selection: PortalNavigationSelection = urlToNavigationSelection(path);

  if (!(selection.taskBundle || selection.interface)) {
    const portalInstances = await registrationService.getEnabledPortalInstancesList();
    const defaultInstance = portalInstances?.length > 0 ? portalInstances[0] : undefined;
    const taskBundles = await registrationService.getTaskBundles(defaultInstance?.namespace);

    if (taskBundles?.length) {
      // @ts-ignore
      selection.taskBundle = { namespace: taskBundles[0].namespace, level: navResourceToLevel(taskBundles[0]) };
    }

    selection.instance = defaultInstance
      ? { namespace: defaultInstance?.namespace, level: NavigationHierarchyLevel.INSTANCE }
      : undefined;
  }

  let initialNavigationState = await getInitialStateFromSelection(selection);

  if (
    initialNavigationState.selectedPortalTaskBundle === undefined &&
    initialNavigationState.requestedPoint === undefined
  ) {
    initialNavigationState = await getInitialStateFromSelection(selection);
  }

  dispatch(new SetInitialized(initialNavigationState));
};

export const initializePortalNavigationFromSelection = async (
  dispatch: React.Dispatch<SetInitialized>,
  selection: PortalNavigationSelection
) => {
  let initialNavigationState = await getInitialStateFromSelection(selection);

  dispatch(new SetInitialized(initialNavigationState));
};
