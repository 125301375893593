/*
 * ===============================================================================================================
 *                                Copyright 2020-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { AuthenticationResult, deleteData, postData, putData } from '@jda/lui-portal-utilities';
import stringify from 'json-stringify-safe';
import { removeTimeoutOtpFromStorage } from '../utils/appUtils';

const { SESSION_SERVICE_URL } = window['env'];
export const STORAGE_KEY = 'lui-session-service-session-id';

const SESSIONS_ENDPOINT = `${SESSION_SERVICE_URL?.trim()}/sessions`;

export type SessionData = { sessionId: string };

export class SessionService {
  private static instance: SessionService;
  private _sessionId: string;

  set sessionId(newSessionId: string) {
    this._sessionId = newSessionId;
  }

  get sessionId(): string {
    return this._sessionId;
  }

  static getInstance: () => SessionService = () => {
    if (!SessionService.instance) {
      SessionService.instance = new SessionService();
    }

    return SessionService.instance;
  };

  persistSessionId = () => {
    const sessionId = window.localStorage.getItem(STORAGE_KEY);
    if (sessionId) {
      this.sessionId = sessionId;
    }
  };

  createSession = async (authToken: AuthenticationResult) => {
    try {
      const res = await postData<SessionData>(
        {
          url: SESSIONS_ENDPOINT,
          tokenProvider: () => Promise.resolve(authToken),
        },
        undefined
      );

      window.localStorage.setItem(STORAGE_KEY, res.sessionId);
      window.dispatchEvent(new StorageEvent('storage', { key: STORAGE_KEY, newValue: res.sessionId }));
      this.sessionId = res.sessionId;
      return Promise.resolve(res);
    } catch (error) {
      console.error(`[Session Service] - createSession Error: ${stringify(error)}`);
      return Promise.reject(error);
    }
  };

  updateSession = async (authToken: AuthenticationResult) => {
    try {
      const res = await putData<SessionData>(
        {
          url: `${SESSIONS_ENDPOINT}/${this.sessionId}`,
          tokenProvider: () => Promise.resolve(authToken),
        },
        undefined
      );

      return Promise.resolve(res);
    } catch (error) {
      console.error(`[Session Service] - updateSession Error: ${stringify(error)}`);
      return Promise.reject(error);
    }
  };

  deleteSession = async (authToken: AuthenticationResult) => {
    try {
      if (this.sessionId) {
        await deleteData({
          url: `${SESSIONS_ENDPOINT}/${this.sessionId}`,
          tokenProvider: () => Promise.resolve(authToken),
        });

        this.sessionId = '';
        window.localStorage.removeItem(STORAGE_KEY);
      }
      removeTimeoutOtpFromStorage();
      return Promise.resolve();
    } catch (error) {
      console.error(`[Session Service] - deleteSession Error: ${stringify(error)}`);
      return Promise.resolve();
    }
  };
}
