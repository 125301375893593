/*
 * ===============================================================================================================
 *                                Copyright 2021-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import {
  IconButton,
  Snackbar as MuiSnackbar,
  SnackbarOrigin,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { AlertSeverity } from '../../models/Message';
import { Tokens } from '@jda/lui-common-component-library';

export interface Props {
  message: any;
  open: boolean;
  severity?: AlertSeverity;
  onClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => void;
  anchorOrigin?: SnackbarOrigin;
  dataTestId?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    barIcon: {
      backgroundColor: Tokens.colors[`${theme.palette.type}Theme`].snackbarBg,
      color: theme.palette.getContrastText(Tokens.colors[`${theme.palette.type}Theme`].snackbarBg),
    },
  })
);

const Snackbar = ({
  open,
  onClose,
  message,
  severity,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  dataTestId = 'lui-portal-snackbar',
}: Props) => {
  const classes: any = useStyles();

  return (
    <MuiSnackbar
      data-testid={dataTestId}
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      message={severity && message}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      {severity !== 'neutral' ? (
        <Alert variant="filled" onClose={onClose} severity={severity}>
          <span data-testid={`${dataTestId}-message`}>{message}</span>
        </Alert>
      ) : (
        <Alert variant="filled" onClose={onClose} className={classes.barIcon}>
          <span data-testid={`${dataTestId}-message`}>{message}</span>
        </Alert>
      )}
    </MuiSnackbar>
  );
};
export default Snackbar;
