/*
 * ===============================================================================================================
 *                                Copyright 2021, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import { createContext, Dispatch, useContext } from 'react';
import { ILocalizer, LanguagePackRequestParameter, getLocalizationFunction } from '@jda/lui-portal-utilities';
import { fallBackLocalizer } from './fallbackLocalizer';
import { I18N_NAMESPACE } from '../../utils/constants';

export const RECEIVE_LOCALIZER: 'RECEIVE_LOCALIZER' = 'RECEIVE_LOCALIZER';

export async function updateLocalizer(requestParameters: LanguagePackRequestParameter): Promise<I18NAction> {
  return getLocalizationFunction(requestParameters).then((localizer) => {
    return { type: RECEIVE_LOCALIZER, localizer: localizer, requestParameters: requestParameters };
  });
}

export interface I18nState {
  initialized: boolean;
  requestParameters: LanguagePackRequestParameter;
  localizer: ILocalizer;
}

export interface I18nContextProps {
  store: I18nState;
  dispatch: Dispatch<I18NAction>;
}

export const i18nContextInitialState = {
  initialized: false,
  localizer: fallBackLocalizer,
  requestParameters: {
    namespace: [I18N_NAMESPACE],
  },
} as I18nState;

export type I18NAction = {
  type: typeof RECEIVE_LOCALIZER;
  requestParameters: LanguagePackRequestParameter;
  localizer: ILocalizer;
};

export const I18nReducer = (state = i18nContextInitialState, action: I18NAction): I18nState => {
  switch (action.type) {
    case RECEIVE_LOCALIZER:
      return {
        ...state,
        localizer: action.localizer,
        requestParameters: action.requestParameters,
        initialized: true,
      };
    default:
      return state;
  }
};

export const I18nContext = createContext({
  store: i18nContextInitialState,
  dispatch: (action: I18NAction) => {},
} as I18nContextProps);

export const useI18nContext = () => useContext(I18nContext);

Object.assign(I18nContext, { displayName: 'I18n' });
