/*
 * ===============================================================================================================
 *                                Copyright 2023-2024, Blue Yonder Group, Inc.
 *                                           All Rights Reserved
 *
 *                               THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF
 *                                          BLUE YONDER GROUP, INC.
 *
 *
 *                         The copyright notice above does not evidence any actual
 *                                 or intended publication of such source code.
 *
 * ===============================================================================================================
 */

import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {
  NavigationHierarchyLevel,
  PortalNavigationInstance,
  PortalNavigationSelection,
  TaskBundleDisplay,
} from '../../models/portalNavigation.model';
import registrationService from '../../service/registrationService';
import { usePortalApp } from '../app/provider';
import PortalNavigationAction, {
  initializePortalNavigationFromSelection,
  SetTaskBundles,
  SetUserInstances,
} from './store/action';
import PortalNavigationReducer, { initialPortalNavigationState, PortalNavigationState } from './store/reducer';
import { usePortalDrawer } from '../application-drawer/provider';

export interface PortalNavigationContextValues {
  portalNavState: PortalNavigationState;
  dispatchPortalNavState: React.Dispatch<PortalNavigationAction>;
  handlePortalInstances: (portalInstances: PortalNavigationInstance[]) => void;
  handleTaskBundle: (namespace: string) => void;
}

interface Props {
  children: React.ReactNode;
}

export function PortalNavigationProvider(props: Props) {
  const [displaySwitchMenu, setDisplaySwitchMenu] = useState(false);
  const [portalNavState, dispatchPortalNavState] = useReducer(PortalNavigationReducer, initialPortalNavigationState);

  const { appState } = usePortalApp();
  const { handleIsChildDrawerPinned, closeChildDrawer } = usePortalDrawer();

  const handlePortalInstances = useCallback(
    (portalInstances: PortalNavigationInstance[]) => {
      dispatchPortalNavState(new SetUserInstances(portalInstances));
    },
    [dispatchPortalNavState]
  );

  const handleTaskBundles = useCallback(
    (taskBundles: TaskBundleDisplay[]) => {
      dispatchPortalNavState(new SetTaskBundles(taskBundles));
    },
    [dispatchPortalNavState]
  );

  const handleTaskBundle = useCallback(
    async (taskBundleNamespace: string) => {
      const selection: PortalNavigationSelection = {
        taskBundle: { namespace: taskBundleNamespace, level: NavigationHierarchyLevel.GLOBAL },
        instance: {
          namespace: portalNavState.activeMenuInstance?.namespace || '',
          level: NavigationHierarchyLevel.GLOBAL,
        },
      };
      await initializePortalNavigationFromSelection(dispatchPortalNavState, selection);
      handleIsChildDrawerPinned(false);
      closeChildDrawer();
    },
    [portalNavState.activeMenuInstance?.namespace]
  );

  useEffect(() => {
    (async () => {
      if (appState.useHierarchicalNavigation) {
        const instances = await registrationService.getEnabledPortalInstancesList();
        handlePortalInstances(instances);
      }
    })();
  }, [appState.useHierarchicalNavigation]);

  useEffect(() => {
    if (portalNavState.portalInstances) {
      (async () => {
        const taskBundles = await registrationService.getTaskBundles(
          portalNavState.activeMenuInstance?.namespace ||
            (portalNavState.portalInstances && portalNavState.portalInstances[0]?.namespace)
        );
        // @ts-ignore
        handleTaskBundles(taskBundles);
      })();
    }
  }, [portalNavState.activeMenuInstance?.namespace, portalNavState.portalInstances]);

  const contextValue: PortalNavigationContextValues = useMemo(() => {
    return {
      portalNavState,
      dispatchPortalNavState,
      handlePortalInstances,
      handleTaskBundle,
    };
  }, [
    portalNavState,
    dispatchPortalNavState,
    displaySwitchMenu,
    setDisplaySwitchMenu,
    handlePortalInstances,
    handleTaskBundle,
  ]);
  return <PortalNavigationContext.Provider value={contextValue}>{props.children}</PortalNavigationContext.Provider>;
}

const PortalNavigationContext = createContext<PortalNavigationContextValues>({
  portalNavState: initialPortalNavigationState,
  dispatchPortalNavState: () => {},
  handlePortalInstances: () => {},
  handleTaskBundle: () => {},
});

export const usePortalNavigation = () => useContext(PortalNavigationContext);

export default PortalNavigationProvider;
